<cdao-card class="card">
    <div [ngClass]="getContainerClass">
        <img [ngClass]="getIconClass" alt="Alert Icon">
        <div class="alert-card__content">
            <h1 [ngClass]="getTitleClass">{{getTitle}}</h1>
            <p class="alert-card__description">{{getDescription}}</p>
        </div>
        <img class="alert-card__close" (click)="disableComponent()" alt="close-icon" >
    </div>
</cdao-card>
