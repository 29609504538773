import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { SessionStorageCryptService } from "src/app/core/services/session-storage-crypt/session-storage-crypt.service";
import {
  PROTECTED_URLS,
  STEP,
} from "../../../../commons/constants/global-constants";

@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private sessionStorageCrypt: SessionStorageCryptService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (PROTECTED_URLS.find((url) => req.url.indexOf(url) != -1)) {
      const expiresAt = Number(this.sessionStorageCrypt.getItem("expires_at"));

      if (expiresAt < Date.now()) {
        sessionStorage.clear();
        this.router.navigateByUrl(STEP.LANDING.URL);
      }

      req = req.clone({
        headers: req.headers.set("Content-Type", "application/json"),
        setHeaders: {
          Authorization: `Bearer ${this.sessionStorageCrypt.getItem(
            "id_token",
          )}`,
        },
      });
    }
    return next.handle(req);
  }
}
