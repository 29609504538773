import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  STEP,
  TEXT_OVERFLOWSBIO,
} from "src/app/commons/constants/global-constants";
import { IWSStateMachineResponse } from "src/app/modules/identity/models/customer-validation/customer-validation-response";
import { LoaderService } from "../../http/interceptors/loader/loader.service";
import { STATUS_CODES_WS } from "../../models/status-codes";
@Injectable({
  providedIn: "root",
})
export class WebSocketValidate {
  constructor(private router: Router, private loader: LoaderService) {}
  validateWsResponse(respWebSocket: IWSStateMachineResponse): boolean {
    const isValid = this.responseWsIsValid(respWebSocket);
    if (!isValid) {
      console.log("isValid----", isValid);

      this.loader.hide();
      if (TEXT_OVERFLOWSBIO[respWebSocket.data.statusCode]) {
        this.router.navigateByUrl(
          `${STEP.OVERFLOWS_BIOMETRIC.URL}/${respWebSocket.data.statusCode}`,
        );
      } else {
        this.router.navigateByUrl(
          `${STEP.CUSTOM_ERRORS.URL}/${respWebSocket.data.statusCode}`,
        );
      }
    }
    return isValid;
  }

  responseWsIsValid(respWebSocket: IWSStateMachineResponse): boolean {
    return (
      respWebSocket.data.statusCode in STATUS_CODES_WS &&
      STATUS_CODES_WS[respWebSocket.data.statusCode].success
    );
  }
}
