import { STEP } from "src/app/commons/constants/global-constants";
import {
  DEFAULT_ASSETS,
  DEFAULT_ERROR_MESSAGE,
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
} from "../customer-status-code-websocket";

export const TIF: {
  [key: string]: IStatusCodesWsError | IStatusCodesWsSuccess;
} = {
  TIF001: {
    code: "TIF001",
    success: true,
    navigateTo: STEP.CARD_DELIVERY.URL,
    eventName:
      "Notificar Solicitud Tarjeta Cliente y No Cliente (cardAssociation, deliveryCard)",
    eventCode: 2213,
    eventMnemonic: "FE_TIF",
  },
  TIF101: {
    code: "TIF101",
    success: false,
    titleScreen: DEFAULT_ASSETS.ESTAMOS_PRESENTANDO_ALGUNOS_INCONVENIENTES,
    messageScreen: "",
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_INVALID_CUSTOMER·SVG,
    showCode: true,
    eventName: DEFAULT_ASSETS.ASOCIACION_TARJETA,
    eventCode: 3103,
    eventMnemonic: "FE_OTPError3",
  },
};
