import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable, zip } from "rxjs";
import { first, retry, switchMap } from "rxjs/operators";
import { AdlFormControl } from "src/app/core/models/adl-form-control";
import {
  STATUS_CODES_WS,
  StatusCodesWSType,
} from "src/app/core/models/status-codes";
import { environment } from "src/environments/environment";
import { ANALYTICS_EVENT_CONFIG } from "../../../../commons/constants/global-constants";
import { CryptoService } from "../../../services/crypto/crypto.service";
import { EVENT_DIRECTORY, IEventDirectory } from "../../config/event.directory";
import { IControlsEventAnalytics, IEventLog } from "../../models/event-message";
import { IAnalyticsEventRequest } from "../../models/event-request";
import { AnalyticsEventBuilderService } from "../event-builder/analytics-event-builder.service";
import { UuidService } from "../uuid/uuid.service";

const analyticsAdlUrl = environment.AdlAnalyticsAdlUrl;

@Injectable({
  providedIn: "root",
})
export class AnalyticsLoggerService {
  private http: HttpClient;
  public eventDirectory: IEventDirectory;

  constructor(
    handler: HttpBackend,
    private analyticsEventBuilder: AnalyticsEventBuilderService,
    private cryptoService: CryptoService,
    private uuidService: UuidService,
  ) {
    this.eventDirectory = EVENT_DIRECTORY;
    this.http = new HttpClient(handler);
  }

  public logAnalyticsEvent(
    eventLog: IEventLog,
    status: boolean,
    controls?: IControlsEventAnalytics,
    form?: FormGroup,
    eventCode?: StatusCodesWSType,
  ) {
    if (eventCode) {
      form.addControl("eventCode", new AdlFormControl(eventCode));
      form.addControl(
        "eventDescription",
        new AdlFormControl(
          STATUS_CODES_WS[eventCode]?.eventName ??
            "Respuesta Web socket no mapeada: " + eventCode,
        ),
      );
    }

    this.buildRequest(eventLog, status, controls, form)
      .pipe(first())
      .subscribe();
  }

  private buildRequest(
    eventLog: IEventLog,
    status: boolean,
    controls?: IControlsEventAnalytics,
    form?: FormGroup,
  ): Observable<object> {
    return zip(
      this.analyticsEventBuilder
        .createEventMessage(eventLog, status, controls, form)
        .pipe(
          switchMap((analyticsEvent) => {
            return this.cryptoService.encryptObject(analyticsEvent);
          }),
        ),
      this.uuidService.generateUUID(),
    ).pipe(
      switchMap(([cypherEvent, uuid]) =>
        this.sendEvent({
          StreamName: ANALYTICS_EVENT_CONFIG.kinesisStream,
          Data: cypherEvent,
          PartitionKey: uuid,
        }),
      ),
    );
  }

  private sendEvent(payload: IAnalyticsEventRequest): Observable<any> {
    return this.http.post<any>(`${analyticsAdlUrl}`, payload).pipe(retry(3));
  }
}
