import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

export interface IAlertInformation {
  type: "success" | "warning" | "danger";
  title: string;
  description: string;
}
const BASE_CLASS = "alert-card";
const ICON = "__icon";
const TITLE = "__title";
const CONTAINER = "__container";
@Component({
  selector: "cdao-alert-card",
  templateUrl: "./alert-card.component.html",
  styleUrls: ["./alert-card.component.scss"],
})
export class AlertCardComponent implements OnInit {
  @Input() alertInformation: IAlertInformation = {
    type: "success",
    title: "Default title",
    description: "This is a default description",
  };
  @Input() index: number;
  @Output() removeAlert = new EventEmitter<number>();

  private type: string;
  private title: string;
  private description: string;

  private iconClass: string;
  private containerClass: string;
  private titleClass: string;
  public ALIVE_TIME = 6200;

  ngOnInit() {
    const { type, title, description } = this.alertInformation;

    this.type = type;
    this.title = title;
    this.description = description;

    this.iconClass = `${BASE_CLASS}${ICON}--${this.type}`;
    this.titleClass = `${BASE_CLASS}${TITLE}--${this.type}`;
    this.containerClass = `${BASE_CLASS}${CONTAINER}--${this.type}`;
    setTimeout(this.disableComponent.bind(this), this.ALIVE_TIME);
  }

  disableComponent() {
    this.removeAlert.emit(this.index);
  }

  public get getTitle(): string {
    return this.title;
  }
  public get getDescription(): string {
    return this.description;
  }

  public get getIconClass(): string {
    return this.iconClass;
  }
  public get getContainerClass(): string {
    return this.containerClass;
  }
  public get getTitleClass(): string {
    return this.titleClass;
  }
}
