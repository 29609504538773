import {
  DEFAULT_ASSETS,
  DEFAULT_ERROR_OPERATORS,
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
} from "../customer-status-code-websocket";

/* Operadores De Seguridad */
export const SOI: {
  [key: string]: IStatusCodesWsError | IStatusCodesWsSuccess;
} = {
  SOI001: {
    code: "SOI001",
    success: true,
    eventName: "Notificar Exito Consulta SOI",
    eventCode: 2222,
    eventMnemonic: "FE_SOI",
  },
  SOI101: {
    ...DEFAULT_ERROR_OPERATORS,
    code: "SOI101",
    eventName: "Error SOI Salario Insuficiente",
    messageScreen: DEFAULT_ASSETS.NO_CUMPLE_CON_POLITICAS_REQUERIDAS,
    motivo: "Sin ingresos mínimos en SOI",
  },
  SOI102: {
    code: "SOI102",
    success: true,
    eventName: "Notificar Error SOI",
    eventCode: 2225,
    eventMnemonic: "FE_SOI2",
  },
  SOI103: {
    code: "SOI103",
    success: false,
    titleScreen: DEFAULT_ASSETS.NO_PODEMOS_ATENDER_LA_SOLICITUD,
    messageScreen: DEFAULT_ASSETS.NO_CUMPLE_CON_POLITICAS_REQUERIDAS,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_INVALID_CUSTOMER·SVG,
    showCode: true,
    eventName: "Ocupación diferente a asalariado en SOI",
    motivo: "Ocupación diferente a asalariado en SOI",
    eventCode: 3405,
    eventMnemonic: "FE_SOIError",
  },
  SOI104: {
    code: "SOI104",
    success: true,
    eventCode: 2234,
    eventMnemonic: "SOI_104",
    eventName: "Error de Consulta de Operador",
  },
};
