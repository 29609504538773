import { STEP } from "src/app/commons/constants/global-constants";
import {
  DEFAULT_ASSETS,
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
  OTP_DEFAULT_ERROR_VALUES,
} from "../customer-status-code-websocket";

/** Generacion One Time Password */
export const OTP: {
  [key: string]: IStatusCodesWsError | IStatusCodesWsSuccess;
} = {
  // ------------- Generación OTP ------------ //
  OTP001: {
    code: "OTP001",
    success: true,
    motivo: "",
    eventName: DEFAULT_ASSETS.GENERACION_OTP,
    navigateTo: STEP.OTP.URL,
    eventCode: 2205,
    eventMnemonic: "FE_OTP",
  },
  OTP101: {
    code: "OTP101",
    success: false,
    titleScreen: DEFAULT_ASSETS.ESTAMOS_PRESENTANDO_ALGUNOS_INCONVENIENTES,
    messageScreen: "Intentalo nuevamente",
    icon: DEFAULT_ASSETS.ASSETS·IMG·LAPTOP·FALLA·SVG,
    showCode: true,
    eventName: DEFAULT_ASSETS.GENERACION_OTP,
    motivo: "Falla pantalla OTP por error técnico",
    eventCode: 2205,
    eventMnemonic: "FE_OTP",
  },
  // USER IDENTITY
  OTP201: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP201",
    motivo: "Banco correo electrónico no coincidente - USER IDENTITY",
  },
  OTP205: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP205",
    motivo: "Parametro no configurado",
  },
  OTP206: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP206",
    motivo:
      "Código de error de número de teléfono no coincidente - USER IDENTITY",
  },
  OTP207: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP207",
    motivo: "Código de error genérico - USER IDENTITY",
  },
  OTP208: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP208",
    motivo: "Movil no encontrado en Cifin - USER IDENTITY",
  },
  OTP209: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP209",
    motivo: "El valor de SendType no es válido - USER IDENTITY",
  },
  OTP210: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP210",
    motivo: "Error de tamaño del apellido  - USER IDENTITY",
  },
  OTP211: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP211",
    motivo: "Código de error del tamaño del teléfono - USER IDENTITY",
  },
  OTP212: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP212",
    motivo:
      "Código de error de tamaño de información de correo electrónico - USER IDENTITY",
  },

  // -------------------------------------------------------------------- //

  // SECURE DATA CLIENT OCCIDENTE

  // OTP23500: {
  //   ...OTP_DEFAULT_ERROR_VALUES,
  //   code: "OTP23500",
  //   motivo: "No se encontro dato seguro activo para el cliente ",
  // },

  // -------------------------------------------------------------------- //

  OTP21: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP21",
    motivo: "Parametro invalido - BUREAUS MANAGEMENT",
  },
  OTP22: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP22",
    motivo: "Contraseña incorrecte - DATACREDITO - CLIENT",
  },
  OTP24: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP24",
    motivo: "Tipo de Documento Incorrecto - DATACREDITO - CLIENT",
  },
  OTP25: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP25",
    motivo: "Número de Documento Incorrecto - DATACREDITO - CLIENT",
  },
  OTP26: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP26",
    motivo: DEFAULT_ASSETS.VALIDACION_DE_IDENTIDAD,
  },
  OTP27: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP27",
    motivo: "No hay implementación para la empresa del SIM consultado",
  },
  OTP28: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP28",
    motivo: "Móviles no encontrados CIFIN",
  },
  OTP29: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP29",
    motivo: DEFAULT_ASSETS.VALIDACION_DE_IDENTIDAD,
  },
  OTP213: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP213",
    motivo: "Éxito - DATACREDITO CLIENT",
  },
  OTP214: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP214",
    motivo: "Información Personal no encontrada - DATACREDITO CLIENT",
  },
  OTP215: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP215",
    motivo: "Validación SIM falló",
  },
  OTP217: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP217",
    motivo: "Contraseña Caducada - DATACREDITO CLIENT",
  },
  OTP221: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP221",
    motivo: "Passed away - DATACREDITO CLIENT",
  },
  OTP224: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP224",
    motivo: "No se envía la OTP desde Latinia - IAS",
  },
  OTP225: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP225",
    motivo: "Acceso Prohibído - DATACREDITO CLIENT",
  },
  OTP226: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP226",
    motivo: "Parametro invalido - DATACREDITO CLIENT",
  },
  OTP227: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP227",
    motivo: "Transacción rechazada - IAS",
  },
  OTP229: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP229",
    motivo: "Cancelado - DATACREDITO CLIENT",
  },
  OTP230: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP230",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP231: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP231",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP232: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP232",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP233: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP233",
  },
  OTP234: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP234",
  },
  OTP235: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP235",
  },
  OTP236: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP236",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP237: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP237",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP238: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP238",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP239: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP239",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP240: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP240",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP241: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP241",
    motivo: "No cumple validación parámetros Cifin",
  },
  OTP242: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP242",
    motivo: "No cumple validación parámetros Cifin",
  },
  OTP243: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP243",
    motivo: "No cumple validación parámetros Cifin",
  },
  OTP244: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP244",
    motivo: "No cumple validación parámetros Datacredito",
  },
  OTP245: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP245",
    motivo: "No cumple validación parámetros Datacredito",
  },
  OTP246: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP246",
    motivo: "No cumple validación parámetros Datacredito",
  },
  OTP247: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP247",
    motivo: "No cumple validación parámetros Cifin",
  },
  OTP248: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP248",
    motivo: "No cumple validación parámetros Datacredito",
  },
  OTP249: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP249",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP250: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP250",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP251: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP251",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP252: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP252",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP253: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP253",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP254: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP254",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP255: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP255",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP256: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP256",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP257: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP257",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP258: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP258",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP259: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP259",
    motivo: DEFAULT_ASSETS.DATACREDITO_ISSUED_OTP,
  },
  OTP260: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP260",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP261: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP261",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP262: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP262",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP263: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP263",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP264: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP264",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP265: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP265",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP266: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP266",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP267: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP267",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP268: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP268",
    eventName: DEFAULT_ASSETS.VALIDACION_DE_IDENTIDAD,
  },
  OTP269: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP269",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP270: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP270",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP271: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP271",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP272: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP272",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP273: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP273",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP274: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP274",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP275: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP275",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP276: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP276",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP277: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP277",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP278: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP278",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP279: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP279",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP280: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP280",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP281: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP281",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP282: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP282",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP283: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP283",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP284: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP284",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP285: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP285",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP286: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP286",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP287: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP287",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP288: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP288",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP289: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP289",
    motivo: DEFAULT_ASSETS.DATACREDITO_UNDEFINED_OTP,
  },
  OTP299: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP299",
    motivo: "En proceso - DATACREDITO CLIENT",
  },
  OTP2100: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2100",
    motivo:
      "La transacción no puede ser procesada. Póngase en contacto con la entidad",
  },
  OTP2101: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2101",
    motivo: "Cancelado por extranjeros",
  },
  OTP2102: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2102",
    motivo: "Cancelado por intento de suplantación de identidad",
  },
  OTP2103: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2103",
    motivo: "Cancelado por mala tramitación",
  },
  OTP2104: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2104",
    motivo: "Cancelado por minoría de edad",
  },
  OTP2105: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2105",
    motivo: "Cancelado por fallecimiento - No emitido",
  },
  OTP2106: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2106",
    motivo: "Doble identificación",
  },
  OTP2107: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2107",
    motivo: "En proceso de elaboración",
  },
  OTP2108: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2108",
    motivo: "Extrangero",
  },
  OTP2109: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2109",
    motivo: "Identidad Falsa",
  },
  OTP2110: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2110",
    motivo: "Interdicción judicial por demanda",
  },
  OTP2111: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2111",
    motivo: "Mala tramitación",
  },
  OTP2112: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2112",
    motivo: "Minoria de edad",
  },
  OTP2113: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2113",
    motivo: "Muerte del titular",
  },
  OTP2114: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2114",
    motivo: "No aplica",
  },
  OTP2115: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2115",
    motivo: "Suspensión por derechos políticos",
  },
  OTP2116: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2116",
    motivo: "Documento no expedido",
  },
  OTP2117: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2117",
    motivo: "Reasignación de sexo",
  },
  OTP2200: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2200",
    motivo: "Reasignación de sexo",
  },
  OTP2300: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2300",
    motivo:
      "No es posible procesar la transacción - Problemas técnicos. Por favor intente más tarde - SIM INQUIRY CLIENT",
  },
  OTP2401: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2401",
    motivo:
      "No es posible procesar la transacción. Comuníquese con la Entidad - SIM INQUIRY CLIENT",
  },
  OTP2700: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2700",
    motivo: "No es posible procesar la transacción - SIM INQUIRY CLIENT",
  },
  OTP2710: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP2710",
    motivo: "Error en la Base de Datos - SIM INQUIRY CLIENT",
  },
  OTP21120: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP21120",
    motivo:
      "No existe información para los criterios seleccionados - SIM INQUIRY CLIENT",
  },
  OTP21860: {
    ...OTP_DEFAULT_ERROR_VALUES,
    code: "OTP21860",
    motivo: "Información inválida - SIM INQUIRY CLIENT",
  },

  // Validación OTP

  OTP002: {
    code: "OTP002",
    success: false,
    eventName: DEFAULT_ASSETS.VALIDACION_OTP,
    titleScreen:
      DEFAULT_ASSETS.HAS_SUPERADO_EL_MÁXIMO_DE_INTENTOS_PARA_VALIDAR_TU_IDENTIDAD,
    messageScreen: "Por favor vuelve a intentarlo en 24 horas",
    icon: DEFAULT_ASSETS.ASSETS·IMG·PROFILE·SVG,
    showCode: false,
    eventCode: 3501,
    eventMnemonic: "FE_OTPError",
  },
  OTP102: {
    code: "OTP102",
    success: false,
    eventName: DEFAULT_ASSETS.VALIDACION_OTP,
    titleScreen:
      DEFAULT_ASSETS.HAS_SUPERADO_EL_MÁXIMO_DE_INTENTOS_PARA_VALIDAR_TU_IDENTIDAD,
    messageScreen: "Por favor vuelve a intentarlo en 24 horas",
    icon: DEFAULT_ASSETS.ASSETS·IMG·PROFILE·DANGER·SVG,
    showCode: false,
    eventCode: 3503,
    eventMnemonic: "FE_OTPError3",
  },
  OTP103: {
    code: "OTP103",
    eventName: DEFAULT_ASSETS.VALIDACION_OTP,
    success: true,
    eventCode: 2215,
    eventMnemonic: "FE_OTP3",
  },
  OTP104: {
    code: "OTP104",
    eventName: DEFAULT_ASSETS.VALIDACION_OTP,
    success: false,
    titleScreen:
      DEFAULT_ASSETS.HAS_SUPERADO_EL_MÁXIMO_DE_INTENTOS_PARA_VALIDAR_TU_IDENTIDAD,
    messageScreen: DEFAULT_ASSETS.INTENTALO_NUEVAMENTE,
    showCode: true,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    eventCode: 3504,
    eventMnemonic: "FE_OTPError4",
  },
  SES101: {
    code: "SES101",
    success: false,
    motivo: "Por tu seguridad no puedes tener mas de una sesión abierta",
    eventName: "Cierrala para continuar por este medio",
    navigateTo: STEP.OVERFLOWS_BIOMETRIC.URL + "/SES101",
    eventCode: 3520,
    eventMnemonic: "FE_PBF",
    titleScreen: "Tiene una sesion activa en otro dispositivo",
    messageScreen: "",
    icon: DEFAULT_ASSETS.ASSETS·IMG·TECHERROR·SVG,
    showCode: true,
  },
  // biometria Facial
  PBF106: {
    code: "PBF106",
    success: false,
    motivo: "Proceso Biometrico Previamente Iniciado",
    eventName: "Proceso Biometrico Previamente Iniciado",
    navigateTo: STEP.OVERFLOWS_BIOMETRIC.URL + "/PBF106",
    eventCode: 3520,
    eventMnemonic: "FE_PBF",
    titleScreen: "Proceso Biometrico no satisfactorio",
    messageScreen: DEFAULT_ASSETS.INTENTALO_NUEVAMENTE,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    showCode: true,
  },
  PBF107: {
    code: "PBF107",
    success: false,
    motivo: "Proceso Biometrico no fue satisfactorio",
    eventName: "Proceso Biometrico no fue satisfactorio",
    navigateTo: STEP.OVERFLOWS_BIOMETRIC.URL + "/PBF107",
    eventCode: 3520,
    eventMnemonic: "FE_PBF",
    titleScreen: "Proceso Biometrico no satisfactorio",
    messageScreen: DEFAULT_ASSETS.INTENTALO_NUEVAMENTE,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    showCode: true,
  },
  PBF108: {
    code: "PBF108",
    eventName: "Captura errada",
    success: false,
    titleScreen: "Captura errada",
    messageScreen: "Cliente debe dirigirse a la oficina",
    showCode: true,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    eventCode: 3508,
    eventMnemonic: "PBFError4",
  },
  PBF109: {
    code: "PBF109",
    eventName: "Documento no valido",
    success: false,
    titleScreen: "Documento no valido o Documento Alterado",
    messageScreen: "Cliente debe dirigirse a la oficina",
    showCode: true,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    eventCode: 3509,
    eventMnemonic: "PBFError4",
  },
  PBF110: {
    code: "PBF110",
    eventName: "Documento Falso",
    success: false,
    titleScreen: "Documento Falso",
    messageScreen: "Cliente debe dirigirse a la oficina",
    showCode: true,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    eventCode: 3510,
    eventMnemonic: "PBFError4",
  },
  PBF111: {
    code: "PBF111",
    eventName: "Rostro No Corresponde",
    success: false,
    titleScreen: "Rostro No Corresponde",
    messageScreen: "Cliente debe dirigirse a la oficina",
    showCode: true,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    eventCode: 3511,
    eventMnemonic: "PBFError4",
  },
  PBF112: {
    code: "PBF112",
    eventName: "Huella No Corresponde",
    success: false,
    titleScreen: "Huella No Corresponde",
    messageScreen: "Cliente debe dirigirse a la oficina",
    showCode: true,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    eventCode: 3512,
    eventMnemonic: "PBFError4",
  },
  PBF113: {
    code: "PBF113",
    eventName: "Telefono+Emei con transacción pendiente",
    success: false,
    titleScreen: "Cliente debe dirigirse a la oficina",
    messageScreen: DEFAULT_ASSETS.INTENTALO_NUEVAMENTE,
    showCode: true,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    eventCode: 3513,
    eventMnemonic: "PBFError4",
  },
  PBF114: {
    code: "PBF114",
    eventName: "Telefono registrado previamente",
    success: false,
    titleScreen: "Cliente debe dirigirse a la oficina",
    messageScreen: DEFAULT_ASSETS.INTENTALO_NUEVAMENTE,
    showCode: true,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    eventCode: 3514,
    eventMnemonic: "PBFError4",
  },
  PBF115: {
    code: "PBF115",
    eventName: "Persona registrada previamente",
    success: false,
    titleScreen:
      "Persona registrada previamente y Persona encontrada en listas de control",
    messageScreen: DEFAULT_ASSETS.INTENTALO_NUEVAMENTE,
    showCode: true,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    eventCode: 3515,
    eventMnemonic: "PBFError4",
  },
  PBF101: {
    code: "PBF101",
    eventName: "Fallas Tecnicas",
    success: false,
    titleScreen: "Lo sentimos, estamos presentando fallas técnicas",
    messageScreen: DEFAULT_ASSETS.INTENTALO_NUEVAMENTE,
    showCode: true,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    eventCode: 3516,
    eventMnemonic: "PBFError4",
  },
  PBF104: {
    code: "PBF104",
    eventName: "Fallas Tecnicas",
    success: false,
    titleScreen: "Lo sentimos, estamos presentando fallas técnicas",
    messageScreen: DEFAULT_ASSETS.INTENTALO_NUEVAMENTE,
    showCode: true,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    eventCode: 3517,
    eventMnemonic: "PBFError4",
  },
  PBF105: {
    code: "PBF105",
    eventName: "Fallas Tecnicas",
    success: false,
    titleScreen: "Lo sentimos, estamos presentando fallas técnicas",
    messageScreen: DEFAULT_ASSETS.INTENTALO_NUEVAMENTE,
    showCode: true,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    eventCode: 3518,
    eventMnemonic: "PBFError4",
  },
  PBF103: {
    code: "PBF103",
    eventName: "Proceso cancelado por el usuario",
    success: false,
    titleScreen: "Proceso cancelado por el usuario",
    messageScreen: DEFAULT_ASSETS.INTENTALO_NUEVAMENTE,
    showCode: true,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    eventCode: 3519,
    eventMnemonic: "PBFError4",
  },
  PBF102: {
    code: "PBF102",
    success: false,
    titleScreen: "Proceso Biometrico Previamente Iniciado",
    motivo: "Proceso Biometrico Previamente Iniciado",
    eventName: "Proceso Biometrico Previamente Iniciado",
    messageScreen: DEFAULT_ASSETS.INTENTALO_NUEVAMENTE,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG,
    navigateTo: STEP.OTP.URL,
    eventCode: 3520,
    showCode: true,
    eventMnemonic: "FE_PBF",
  },
  PBF005: {
    code: "PBF005",
    success: true,
    motivo: "Proceso Biometrico Previamente Iniciado",
    eventName: "Proceso Biometrico Previamente Iniciado",
    eventCode: 3520,
    eventMnemonic: "FE_PBF",
  },
  PBF006: {
    code: "PBF006",
    success: true,
    motivo: "Proceso Biometrico Previamente Iniciado",
    eventName: "Proceso Biometrico Previamente Iniciado",
    eventCode: 3520,
    eventMnemonic: "FE_PBF",
  },
  PBF003: {
    code: "PBF003",
    success: true,
    motivo: "Proceso Satisfactorio",
    eventName: "Proceso Satisfactorio",
    eventCode: 3521,
    eventMnemonic: "FE_PBF",
  },
  PBF004: {
    code: "PBF004",
    success: true,
    motivo: "Proceso de carga",
    eventName: "Proceso de carga",
    eventCode: 3589,
    eventMnemonic: "FE_PBF",
  },
  PBF002: {
    code: "PBF002",
    success: true,
    motivo: "Proceso de carga",
    eventName: "Proceso de carga",
    eventCode: 3554,
    eventMnemonic: "FE_PBF",
  },
  PBF001: {
    code: "PBF001",
    success: true,
    motivo: "Proceso Biometrico",
    eventName: "Proceso Biometrico",
    navigateTo: STEP.BIOMETRIC.URL,
    eventCode: 3522,
    eventMnemonic: "FE_PBF",
  },
};
