import { DecimalPipe, registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import localEs from "@angular/common/locales/es";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JWT_OPTIONS, JwtHelperService } from "@auth0/angular-jwt";
import { ADLCoreModule } from "@avaldigitallabs/adl-core";
import { OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AlertContainerModule } from "./core/alert/alert-container/alert-container.module";
import { InterceptorError } from "./core/http/interceptors/error/interceptor-error";
import { InterceptorService } from "./core/http/interceptors/headers/interceptor.service";
import { LoaderInterceptor } from "./core/http/interceptors/loader/loader.interceptor";
import { LoaderComponentModule } from "./core/http/interceptors/loader/loader.interceptor.module";
import { ModalWebsocketDisconnectModule } from "./core/modal-websocket-disconnect/modal-websocket-disconnect.module";
import { SessionStorageCryptService } from "./core/services/session-storage-crypt/session-storage-crypt.service";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ADLCoreModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    LoaderComponentModule,
    AlertContainerModule,
    ModalWebsocketDisconnectModule,
  ],
  providers: [
    DecimalPipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorError, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    { provide: OAuthStorage, useClass: SessionStorageCryptService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

registerLocaleData(localEs, "es-CO");
