import { LocationStrategy } from "@angular/common";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { FormMemoryStorageService } from "@avaldigitallabs/adl-core";
import { STEP } from "../../../../commons/constants/global-constants";
import { RouteLineTimeService } from "../../route-linetime/route-linetime.service";

@Injectable({
  providedIn: "root",
})
export class WorkflowGuard implements CanActivate {
  private auxExcludeReloadUrl = false;
  private urlsExcludesReload: string[] = [STEP.FILTER_QUESTIONS.URL];
  private urlsExcludesBackButton: string[] = [
    STEP.IDENTITY.URL,
    STEP.ACCOUNT_TYPE.URL,
    STEP.ACCOUNT_TYPE_DETAILS.URL,
    STEP.ACCOUNT_TYPE_NOMINA.URL,
    STEP.ACCOUNT_AGREEMENT.URL,
    STEP.ACCOUNT_TYPE_AGREEMENT.URL,
    STEP.PERSONAL_INFO_2.URL,
    STEP.LABOR_DATA_2.URL,
    STEP.DELIVERY_CARD.URL,
    STEP.SECURE_DATA.URL,
  ];
  private urlLanding = "";
  constructor(
    private fs: FormMemoryStorageService,
    private router: Router,
    public routeLineTimeService: RouteLineTimeService,
    private location: LocationStrategy,
  ) {
    this.location.onPopState((event) => {
      if (!this.excludeUrl(this.router.url, this.urlsExcludesBackButton)) {
        history.go(1);
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.auxExcludeReloadUrl) {
      this.auxExcludeReloadUrl = this.excludeUrl(
        route.routeConfig.path,
        this.urlsExcludesReload,
      );
    }
    if (this.auxExcludeReloadUrl) {
      return true;
    } else {
      this.router.navigate([this.urlLanding]);
      return true;
    }
  }

  private excludeUrl(url: string, urlsExcludes: string[]) {
    if (url === "/" || url === "") {
      return true;
    }
    for (const urlExclude of urlsExcludes) {
      if (url.includes(urlExclude)) {
        return true;
      }
    }
    return false;
  }
}
