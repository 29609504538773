<div class="progress">
  <div #dynamic
   class="progress-bar progress-bar-striped" 
   role="progressbar" 
   aria-valuenow="0" 
   aria-valuemin="0" 
   aria-valuemax="100" 
   [ngStyle]="style"
   >
    <span id="current-progress"></span>
  </div>
</div>