import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AlertCardModule } from "../alert-card/alert-card.module";
import { AlertContainerComponent } from "./alert-container.component";
@NgModule({
  declarations: [AlertContainerComponent],
  exports: [AlertContainerComponent],
  imports: [CommonModule, AlertCardModule],
})
export class AlertContainerModule {}
