import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import player from "lottie-web";
import { LottieModule } from "ngx-lottie";
import { ModalComponentModule } from "../modal/modal.component.module";

import { ModalWebsocketDisconnectComponent } from "./modal-websocket-disconnect.component";
export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [ModalWebsocketDisconnectComponent],
  imports: [
    CommonModule,
    ModalComponentModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  exports: [ModalWebsocketDisconnectComponent],
})
export class ModalWebsocketDisconnectModule {}
