import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CryptoService {
  public encryptObject(data: object): Observable<string> {
    return of(this.encrypt(JSON.stringify(data)));
  }

  public encrypt(data: string): string {
    const encrypted = CryptoJS.AES.encrypt(data, environment.cypherJsKey);
    return encrypted.toString();
  }

  public decrypt(cipherText: string): string {
    const bytes = CryptoJS.AES.decrypt(cipherText, environment.cypherJsKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
