import {
  DEFAULT_ASSETS,
  DEFAULT_ERROR_MESSAGE,
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
} from "../customer-status-code-websocket";

export const CLR: {
  [key: string]: IStatusCodesWsError | IStatusCodesWsSuccess;
} = {
  CLR001: {
    code: "CLR001",
    success: true,
    eventName: "Notificar Exito Consulta Lista Restrictiva",
    eventCode: 2203,
    eventMnemonic: "FE_CLR1",
  },
  CLR002: {
    code: "CLR002",
    success: true,
    eventName: "Notificar Cliente en Lista Restrictiva",
    eventCode: 2204,
    eventMnemonic: "FE_CLR2",
  },
  CLR101: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "CLR101",
    titleScreen: DEFAULT_ASSETS.NO_PODEMOS_ATENDER_LA_SOLICITUD,
    messageScreen: DEFAULT_ASSETS.NO_CUMPLE_CON_POLITICAS_REQUERIDAS,
    eventName: "Riesgo Cliente",
    motivo: "Coincidencia en Listas Restrictivas",
    success: false,
  },
};
