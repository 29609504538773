import {
  DEFAULT_ASSETS,
  DEFAULT_ERROR_MESSAGE,
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
} from "../customer-status-code-websocket";

const CRM_CLIENT = "Consulta Infromacion Cliente CRM";

export const CIC: {
  [key: string]: IStatusCodesWsError | IStatusCodesWsSuccess;
} = {
  CIC001: {
    code: "CIC001",
    success: true,
    eventName: "Notificar Exito Consulta Info Cliente",
    eventCode: 2202,
    eventMnemonic: "FE_CIC",
  },
  CIC101: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "CIC101",
    eventName: CRM_CLIENT,
    motivo: "Falla servicio consulta cliente en CRM",
  },
  CIC102: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "CIC102",
    eventName: "Error Telefono Cliente",
  },
  CIC103: {
    code: "CIC103",
    success: false,
    titleScreen: DEFAULT_ASSETS.LOS_DATOS_DEL_CLIENTE_NO_ESTÁN_ACTUALIZADOS,
    messageScreen:
      DEFAULT_ASSETS.POR_FAVOR_ACTUALIZA_LOS_DATOS_E_INTENTA_NUEVAMENTE,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_OUTDATED_DATA·SVG,
    showCode: true,
    eventName: CRM_CLIENT,
    motivo: "Correo electrónico no coincide",
    eventCode: 3308,
    eventMnemonic: "FE_CICError",
  },
  CIC104: {
    code: "CIC104",
    success: false,
    titleScreen: DEFAULT_ASSETS.LOS_DATOS_DEL_CLIENTE_NO_ESTÁN_ACTUALIZADOS,
    messageScreen:
      DEFAULT_ASSETS.POR_FAVOR_ACTUALIZA_LOS_DATOS_E_INTENTA_NUEVAMENTE,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_OUTDATED_DATA·SVG,
    showCode: true,
    eventName: CRM_CLIENT,
    eventCode: 3309,
    eventMnemonic: "FE_CICError",
  },
  CIC105: {
    code: "CIC105",
    success: false,
    titleScreen: DEFAULT_ASSETS.LOS_DATOS_DEL_CLIENTE_NO_ESTÁN_ACTUALIZADOS,
    messageScreen:
      DEFAULT_ASSETS.POR_FAVOR_ACTUALIZA_LOS_DATOS_E_INTENTA_NUEVAMENTE,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_OUTDATED_DATA·SVG,
    showCode: true,
    eventName: CRM_CLIENT,
    motivo: "Datos desactualizados en CRM",
    eventCode: 3310,
    eventMnemonic: "FE_CICError",
  },
};
