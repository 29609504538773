import {
  DEFAULT_ASSETS,
  DEFAULT_ERROR_OPERATORS,
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
} from "../customer-status-code-websocket";

/* Mareigua */
export const MRG: {
  [key: string]: IStatusCodesWsError | IStatusCodesWsSuccess;
} = {
  MRG001: {
    code: "MRG001",
    success: true,
    eventName: "Notificar Exito Consulta Mareigua",
    eventCode: 2221,
    eventMnemonic: "FE_MRG",
  },
  MRG101: {
    ...DEFAULT_ERROR_OPERATORS,
    code: "MRG101",
    eventName: "Error Mareigua Salario Insuficiente",
    messageScreen: DEFAULT_ASSETS.NO_CUMPLE_CON_POLITICAS_REQUERIDAS,
    motivo: "Sin ingresos mínimos en Mareigua",
    success: false,
  },
  MRG102: {
    code: "MRG102",
    success: true,
    eventName: "Notificar Error Mareigua",
    eventCode: 2224,
    eventMnemonic: "FE_SOI2",
  },
  MRG103: {
    code: "MRG103",
    success: false,
    titleScreen: DEFAULT_ASSETS.NO_PODEMOS_ATENDER_LA_SOLICITUD,
    messageScreen: DEFAULT_ASSETS.NO_CUMPLE_CON_POLITICAS_REQUERIDAS,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_INVALID_CUSTOMER·SVG,
    showCode: true,
    eventName: "Ocupación diferente a asalariado en Mareigua",
    motivo: "Ocupación diferente a asalariado en Mareigua",
    eventCode: 3404,
    eventMnemonic: "FE_MRGError",
  },
  MRG104: {
    code: "MRG104",
    success: true,
    eventName: "Error de Consulta de Operador",
    eventCode: 2230,
    eventMnemonic: "MRG_104",
  },
};
