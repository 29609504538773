import { STEP } from "src/app/commons/constants/global-constants";
import {
  DEFAULT_ASSETS,
  DEFAULT_ERROR_MESSAGE,
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
} from "../customer-status-code-websocket";

const EVENT_NAME = "Time Up espera pantallas";
export const UNIQUE_CODES: {
  [key: string]: IStatusCodesWsError | IStatusCodesWsSuccess;
} = {
  ATD001: {
    code: "ATD001",
    success: true,
    eventName: "Notificar Asociación Tarjeta Debito",
    eventCode: 2214,
    eventMnemonic: "AS_TD",
  },
  ATT101: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "ATT101",
    eventName: "Notificar error en la asignación de topes transaccionales",
  },
  CRM101: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "CRM101",
    eventName: "Error Consulta Cliente CRM",
    motivo: "Falla en servicio creación cliente CRM",
  },
  CRL101: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "CRL101",
    messageScreen: DEFAULT_ASSETS.NO_CUMPLE_CON_POLITICAS_REQUERIDAS,
    eventName: "Error Perfil Nivel de Riesgo Cliente",
    motivo: "Error Consulta Perfil Nivel de Riesgo Cliente ",
  },
  CRM001: {
    code: "CRM001",
    success: true,
    eventName: "Notificar Exito Creación Cliente CRM",
    eventCode: 2208,
    eventMnemonic: "FE_CRM",
  },

  DCA101: {
    code: "DCA101",
    success: false,
    titleScreen: "No podemos atender la solicitud",
    messageScreen:
      "Encontramos que ya existe una cuenta de este tipo con el mismo número de identificación",
    icon: "assets/img/ic-same-id.svg",
    showCode: true,
    eventName: "Cuenta duplicada",
    eventCode: 3303,
    eventMnemonic: "FE_UCError",
  },
  DIF001: {
    code: "DIF001",
    success: true,
    navigateTo: STEP.VALIDATE_ADDRESS.URL,
    eventName: "Enviar y Solicitar Dirección Cliente (CmrPerfilNum)",
    eventCode: 2215,
    eventMnemonic: "FE_DIF",
  },
  DIF101: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "DIF101",
    eventName: EVENT_NAME,
  },
  DIF102: {
    code: "DIF102",
    success: false,
    titleScreen: DEFAULT_ASSETS.LOS_DATOS_DEL_CLIENTE_NO_ESTÁN_ACTUALIZADOS,
    messageScreen:
      DEFAULT_ASSETS.POR_FAVOR_ACTUALIZA_LOS_DATOS_E_INTENTA_NUEVAMENTE,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_OUTDATED_DATA·SVG,
    showCode: true,
    eventName: "Notificar Error de Dirección Cliente",
    motivo: "Dirección residencia desactualizada",
    eventCode: 3304,
    eventMnemonic: "FE_UCError",
  },
  ERD101: {
    code: "ERD101",
    success: false,
    titleScreen: "WebSocket desconectado",
    messageScreen: DEFAULT_ASSETS.INTENTALO_NUEVAMENTE,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_INVALID_CUSTOMER·SVG,
    showCode: true,
    eventName: "Error de desconexión de websocket",
    eventCode: 3305,
    eventMnemonic: "FE_UCError",
  },
  ERD102: {
    code: "ERD102",
    success: false,
    titleScreen: "WebSocket desconectado",
    messageScreen: DEFAULT_ASSETS.INTENTALO_NUEVAMENTE,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_INVALID_CUSTOMER·SVG,
    showCode: true,
    eventName: "Error de desconexión temporal de websocket",
    eventCode: 3306,
    eventMnemonic: "FE_UCError",
  },
  ERG001: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "ERG001",
    eventName: "General error",
  },
  FIF001: {
    code: "FIF001",
    success: true,
    navigateTo: STEP.FINANCIAL_DATA.URL,
    eventName: EVENT_NAME,
    eventCode: 2211,
    eventMnemonic: "FE_FIF",
  },
  FIF101: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "FIF101",
    eventName: EVENT_NAME,
  },
  INC408: {
    code: "INC408",
    success: false,
    titleScreen: "La conexión caducó",
    messageScreen:
      "Has excedido el tiempo máximo de inactividad en la plataforma",
    icon: DEFAULT_ASSETS.ASSETS·IMG·PROFILE·SVG,
    showCode: true,
    eventName: "Error Disponibilidad de Tiempo",
    isButtonVisible: true,
    eventCode: 3307,
    eventMnemonic: "FE_UCError",
  },
  LIF001: {
    code: "LIF001",
    success: true,
    navigateTo: STEP.LABOR_DATA_1.URL,
    eventName: "Notificar Solicitud información laboral al cliente",
    eventCode: 2210,
    eventMnemonic: "FE_LIF",
  },
  LIF101: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "LIF101",
    eventName: EVENT_NAME,
  },
  MCH101: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "MCH101",
    eventName: "Maquina de estados murió",
  },
  OFI001: {
    code: "OFI001",
    success: true,
    navigateTo: STEP.OFFICE_ASSOCIATION.URL,
    eventName: "Solicitar Info Oficina Cliente",
    eventCode: 2218,
    eventMnemonic: "FE_OFI",
  },
  PF101: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "PF101",
    eventName: "Error Preguntas de validación",
    navigateTo: STEP.PERSONAL_INFO.URL,
  },
  PIF001: {
    code: "PIF001",
    success: true,
    eventName: "Notificar Solicitar información cliente",
    navigateTo: STEP.PERSONAL_INFO.URL,
    eventCode: 2209,
    eventMnemonic: "FE_PIF",
  },
  PIF101: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "PIF101",
    eventName: EVENT_NAME,
  },
  PIF102: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "PIF102",
    eventName: "Error código dane",
  },
  SIT001: {
    code: "SIT001",
    success: true,
    navigateTo: STEP.MONTHLY_TRANSACTIONS.URL,
    eventName: "Solicitar info transacciones",
    eventCode: 2219,
    eventMnemonic: "FE_SIF",
  },
  VLE001: {
    code: "VLE001",
    success: true,
    // navigateTo: STEP.IDENTITY.URL,
    eventName: "Solicitar info transacciones",
    eventCode: 2219,
    eventMnemonic: "FE_SIF",
  },
};
