import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { STEP } from "./commons/constants/global-constants";
import { AccessTokenGuardService } from "./core/services/access-token-guard/access-token-guard.service";
import { WorkflowGuard } from "./core/services/auth/workflow-guard/workflow.guard";

export const routes = [
  {
    path: "",
    loadChildren: () =>
      import("./modules/landing/landing.module").then((m) => m.LandingModule),
    canActivate: [WorkflowGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import("./modules/layout/layout.module").then((m) => m.LayoutModule),
  },
  {
    path: STEP.ACCOUNT_CONFIRM.URL,
    loadChildren: () =>
      import("./modules/account-confirm/account-confirm.module").then(
        (m) => m.AccountConfirmModule,
      ),
    canActivate: [WorkflowGuard, AccessTokenGuardService],
  },
  {
    path: `${STEP.CUSTOM_ERRORS.URL}/:statusCode`,
    loadChildren: () =>
      import("./modules/custom-errors/custom-errors.module").then(
        (m) => m.CustomErrorsModule,
      ),
    canActivate: [WorkflowGuard, AccessTokenGuardService],
  },
  {
    path: "",
    redirectTo: "",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
