import {
  DEFAULT_ASSETS,
  DEFAULT_ERROR_OPERATORS,
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
} from "../customer-status-code-websocket";

/* Aportes en Linea */
export const AEL: {
  [key: string]: IStatusCodesWsError | IStatusCodesWsSuccess;
} = {
  AEL001: {
    code: "AEL001",
    success: true,
    eventName: "Notificar Exito Consulta AEL",
    eventCode: 2220,
    eventMnemonic: "FE_AEL",
  },
  AEL101: {
    ...DEFAULT_ERROR_OPERATORS,
    code: "AEL101",
    eventName: "Error AEL Salario Insuficiente",
    messageScreen: DEFAULT_ASSETS.NO_CUMPLE_CON_POLITICAS_REQUERIDAS,
    motivo: "Sin ingresos mínimos en Aportes en Línea",
  },
  AEL102: {
    code: "AEL102",
    success: true,
    eventName: "Notificar Error AEL",
    eventCode: 2223,
    eventMnemonic: "FE_SOI2",
  },
  AEL103: {
    code: "AEL103",
    success: false,
    titleScreen: DEFAULT_ASSETS.NO_PODEMOS_ATENDER_LA_SOLICITUD,
    messageScreen: DEFAULT_ASSETS.NO_CUMPLE_CON_POLITICAS_REQUERIDAS,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_INVALID_CUSTOMER·SVG,
    showCode: true,
    eventName: "Ocupación diferente a asalariado en Aportes en Línea",
    motivo: "Ocupación diferente a asalariado en Aportes en Línea",
    eventCode: 3403,
    eventMnemonic: "FE_AELError",
  },
  AEL104: {
    code: "AEL104",
    success: true,
    eventCode: 2232,
    eventMnemonic: "AEL_104",
    eventName: "Error de Consulta de Operador",
  },
};
