/* import {
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
} from "./customer-status-code-websocket"; */

import { ACA } from "./codes/ACA";
import { AEL } from "./codes/AEL";
import { AST } from "./codes/AST";
import { CDP } from "./codes/CDP";
import { CDS } from "./codes/CDS";
import { CIC } from "./codes/CIC";
import { CLR } from "./codes/CLR";
import { CRM } from "./codes/CRM";
import { MRG } from "./codes/MRG";
import { OPR } from "./codes/OPR";
import { OTP } from "./codes/OTP";
import { SOI } from "./codes/SOI";
import { TIF } from "./codes/TIF";

import { UNIQUE_CODES } from "./codes/UNIQUE_CODES";

export interface IStatusCodesWsError {
  code: StatusCodesWSType;
  success: false;
  eventName: string;
  icon: string;
  showCode: boolean;
  messageScreen: string;
  titleScreen: string;
  isButtonVisible?: boolean;
  navigateTo?: string;
  motivo?: string;
  action?: () => any;
  eventCode: number;
  eventMnemonic: string;
}

export interface IStatusCodesWsSuccess {
  code: StatusCodesWSType;
  success: true;
  eventName: string;
  navigateTo?: string;
  eventCode: number;
  eventMnemonic: string;
  motivo?: string;
}

export const STATUS_CODES_WS: {
  [key: string]: IStatusCodesWsError | IStatusCodesWsSuccess;
} = {
  ...UNIQUE_CODES,
  ...ACA,
  ...AEL,
  ...AST,
  ...CDP,
  ...CIC,
  ...CLR,
  ...CRM,
  ...MRG,
  ...OPR,
  ...OTP,
  ...SOI,
  ...TIF,
  ...CDS,
};
export type StatusCodesWSType = keyof typeof STATUS_CODES_WS;
