import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ILoaderState } from "./models.loader";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  progress = new Subject<number>();
  loaderSubject = new Subject<ILoaderState>();

  show() {
    this.loaderSubject.next(({ show: true } as any) as ILoaderState);
  }
  hide() {
    this.loaderSubject.next(({ show: false } as any) as ILoaderState);
    this.progress.next(null);
  }
}
