import { STEP } from "src/app/commons/constants/global-constants";

export enum DEFAULT_ASSETS {
  ESTAMOS_PRESENTANDO_ALGUNOS_INCONVENIENTES = "Estamos presentando algunos inconvenientes",
  INTENTALO_NUEVAMENTE = "Inténtalo nuevamente",
  ASSETS·IMG·TECHERROR·SVG = "assets/img/ca-tech-error.svg",
  ASSETS·IMG·CA_INVALID_CUSTOMER·SVG = "assets/img/ca-invalid-customer.svg",
  ASSETS·IMG·PROFILE·SVG = "assets/img/icono-ilustrado-5-ilstraci-n-profile.svg",
  ASSETS·IMG·PROFILE·DANGER·SVG = "assets/img/icono-ilustrado-5-ilstraci-n-profile-danger.svg",
  ASSETS·IMG·LAPTOP·FALLA·SVG = "assets/img/icono-ilustrado-5-ilstraci-n-laptop-falla-tecnico.svg",
  ASSETS·IMG·CA_UN_AUTHENTICATE_USER·SVG = "assets/img/ca-un-authenticate-user.svg",
  ASSETS·IMG·CA_AVAILABILITY_TIME·SVG = "assets/img/ca-availability-time.svg",

  LOS_DATOS_DEL_CLIENTE_NO_ESTÁN_ACTUALIZADOS = "Los datos del cliente no están actualizados",
  EVALUAMOS_LA_SOLICITUD_Y_NO_PUDIMOS_VALIDAR_LA_IDENTIDAD_DEL_CLIENTE = "Evaluamos la solicitud y no pudimos validar la identidad del cliente",
  NO_CUMPLE_CON_POLITICAS_REQUERIDAS = "No cumple con las políticas requeridas del producto",
  NO_PODEMOS_ATENDER_LA_SOLICITUD = "No podemos atender la solicitud",
  ASSETS·IMG·CA_OUTDATED_DATA·SVG = "assets/img/ca-outdated-data.svg",
  POR_FAVOR_ACTUALIZA_LOS_DATOS_E_INTENTA_NUEVAMENTE = "Por favor actualiza los datos e intenta nuevamente",
  HAS_SUPERADO_EL_MÁXIMO_DE_INTENTOS_PARA_VALIDAR_TU_IDENTIDAD = "Has superado el máximo de intentos para validar tu identidad",
  EVALUAMOS_LA_SOLICITUD_Y_NO_CUMPLE_CON_LAS_POLÍTICAS = "Evaluamos la solicitud y no cumple con las políticas",

  VALIDACION_DE_IDENTIDAD = "Validación de Identidad",
  GENERACION_OTP = "Generacíon OTP",
  VALIDACION_OTP = "Validación OTP",
  ASOCIACION_TARJETA = "Error Asociación Tarjeta",
  DATACREDITO_ISSUED_OTP = "No se emite",
  DATACREDITO_UNDEFINED_OTP = "Indefinido",
}

export const OTP_DEFAULT_ERROR_VALUES: IStatusCodesWsError = {
  code: null,
  success: false,
  titleScreen: DEFAULT_ASSETS.NO_PODEMOS_ATENDER_LA_SOLICITUD,
  messageScreen:
    DEFAULT_ASSETS.EVALUAMOS_LA_SOLICITUD_Y_NO_PUDIMOS_VALIDAR_LA_IDENTIDAD_DEL_CLIENTE,
  icon: DEFAULT_ASSETS.ASSETS·IMG·CA_INVALID_CUSTOMER·SVG,
  showCode: true,
  eventName: DEFAULT_ASSETS.GENERACION_OTP,
  eventCode: 2205,
  eventMnemonic: "FE_OTP",
};

export const DEFAULT_ERROR_MESSAGE: IStatusCodesWsError = {
  code: null,
  eventName: null,
  success: false,
  titleScreen: DEFAULT_ASSETS.ESTAMOS_PRESENTANDO_ALGUNOS_INCONVENIENTES,
  messageScreen: DEFAULT_ASSETS.INTENTALO_NUEVAMENTE,
  icon: DEFAULT_ASSETS.ASSETS·IMG·CA_INVALID_CUSTOMER·SVG,
  showCode: true,
  eventCode: 3300,
  eventMnemonic: "FE_ACAError",
};

export const DEFAULT_ERROR_OPERATORS: IStatusCodesWsError = {
  code: null,
  success: false,
  titleScreen: DEFAULT_ASSETS.NO_PODEMOS_ATENDER_LA_SOLICITUD,
  messageScreen:
    DEFAULT_ASSETS.EVALUAMOS_LA_SOLICITUD_Y_NO_CUMPLE_CON_LAS_POLÍTICAS,
  icon: DEFAULT_ASSETS.ASSETS·IMG·CA_INVALID_CUSTOMER·SVG,
  showCode: true,
  eventName: null,
  eventCode: 3400,
  eventMnemonic: "FE_OPRError",
};

export interface IStatusCodesWsError {
  // code: StatusCodesWSType;
  code: string;
  success: false;
  eventName: string;
  icon: string;
  showCode: boolean;
  messageScreen: string;
  titleScreen: string;
  isButtonVisible?: boolean;
  navigateTo?: string;
  motivo?: string;
  action?: () => any;
  eventCode: number;
  eventMnemonic: string;
}

export interface IStatusCodesWsSuccess {
  // code: StatusCodesWSType;
  motivo?: string;
  code: string;
  success: true;
  eventName: string;
  navigateTo?: string;
  eventCode: number;
  eventMnemonic: string;
}
