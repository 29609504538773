import {
  DEFAULT_ASSETS,
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
} from "../customer-status-code-websocket";

export const CDP: {
  [key: string]: IStatusCodesWsError | IStatusCodesWsSuccess;
} = {
  CDP102: {
    code: "CDP102",
    success: false,
    titleScreen: "En este momento no podemos atenderte",
    messageScreen:
      "Te recordamos que el tiempo de disponibilidad es de Lunes a Sábado de 7:00 a.m. a 7:00 p.m.",
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_AVAILABILITY_TIME·SVG,
    showCode: true,
    eventName: "Error Disponibilidad de Tiempo",
    isButtonVisible: false,
    eventCode: 3901,
    eventMnemonic: "FE_CDPError",
  },
  CDP101: {
    code: "CDP101",
    success: false,
    titleScreen: "Estamos trabajando para ti",
    messageScreen:
      "En este momento estamos mejorando la herramienta, terminaremos lo más pronto posible",
    icon: "assets/img/ca-working.svg",
    showCode: true,
    eventName: "Error Estamos trabajando para mejorar",
    isButtonVisible: false,
    eventCode: 3902,
    eventMnemonic: "FE_CDPError",
  },
  CDP001: {
    code: "CDP001",
    success: true,
    eventName: "Notificar Exito Horario y Disponibilidad ",
    eventCode: 2201,
    eventMnemonic: "FE_CDP",
  },
};
