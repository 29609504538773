import { EventEmitter, Injectable } from "@angular/core";
import { IAlertInformation } from "../alert-card/alert-card.component";

@Injectable({
  providedIn: "root",
})
export class AlertProvider {
  public pushAlertEmitter: EventEmitter<IAlertInformation>;
  constructor() {
    this.pushAlertEmitter = new EventEmitter<IAlertInformation>();
  }

  public pushAlert(alert: IAlertInformation) {
    this.pushAlertEmitter.emit(alert);
  }
}
