import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  FormControl,
  ValidatorFn,
} from "@angular/forms";
import { InputValidationMsg } from "./input-validation-msg";

export class AdlFormControl extends FormControl implements AbstractControl {
  public info: any = {};
  public warnings: any = {};
  public pendings: any = {};
  public messages: InputValidationMsg = new InputValidationMsg();

  constructor(
    formState?: any,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(formState, validatorOrOpts, asyncValidator);
  }

  setErrorMsg(key: string, msg: string) {
    if (!this.messages.errors) {
      this.messages.errors = {};
    }

    this.messages.errors[key] = msg;
  }
}
