import { Component, OnDestroy, OnInit } from "@angular/core";
import { LoaderService } from "./loader.service";
import { ILoaderState } from "./models.loader";
@Component({
  selector: "cdao-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit, OnDestroy {
  public lottieConfig: any;
  private anim: any;
  private speed = 1.5;
  private stack: ILoaderState[] = [];
  _progress: number = null;
  get progress(): number {
    return this._progress;
  }
  set progress(progress: number) {
    progress = progress > 100 ? 100 : progress;
    this._progress = progress !== null ? Math.round(progress) : progress;
  }
  get show(): boolean {
    return this.stack.length > 0;
  }
  styles: Partial<CSSStyleDeclaration> = {
    width: "120px",
    height: "120px",
  };

  constructor(private _loaderService: LoaderService) {
    this.lottieConfig = {
      path: "assets/animations/bocc-spinner.json",
      renderer: "svg",
      autoplay: true,
      loop: true,
    };
  }

  handleAnimation(anim: any) {
    this.anim = anim;
    this.anim.setSpeed(this.speed);
  }

  ngOnInit() {
    this._loaderService.progress.subscribe((progress) => {
      this.progress = progress;
    });
    this._loaderService.loaderSubject.subscribe((res) => {
      if (res.show) {
        let notShow = true;
        this.stack.forEach((stack) => {
          notShow = false;
        });
        if (notShow) {
          this.stack.push(res);
        }
      } else {
        this.stack.pop();
      }
    });
  }
  ngOnDestroy() {
    this._loaderService.loaderSubject.unsubscribe();
  }
}
