import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { IGNORE_LOADER_INTERCEPTOR_URLS } from "../../../../commons/constants/global-constants";
import { LoaderService } from "./loader.service";

let progress = null;
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private _loaderService: LoaderService) {
    this._loaderService.progress.subscribe((progressResp) => {
      progress = progressResp;
    });
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!IGNORE_LOADER_INTERCEPTOR_URLS.find((url) => url === req.url)) {
      this._loaderService.show();
      return next.handle(req).pipe(
        finalize(() => {
          if (progress === null || progress === 100) {
            this._loaderService.hide();
          }
        }),
      );
    } else {
      return next.handle(req).pipe(finalize(() => {}));
    }
  }
}
