import { STEP } from "src/app/commons/constants/global-constants";
import {
  DEFAULT_ASSETS,
  DEFAULT_ERROR_MESSAGE,
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
} from "../customer-status-code-websocket";

export const ACA: {
  [key: string]: IStatusCodesWsError | IStatusCodesWsSuccess;
} = {
  ACA001: {
    code: "ACA001",
    success: true,
    eventName: "Notificar Exito Creación Cuenta",
    navigateTo: STEP.ACCOUNT_CONFIRM.URL,
    eventCode: 2206,
    eventMnemonic: "FE_ACA1",
  },
  ACA002: {
    code: "ACA002",
    success: true,
    eventName: "Notificar Apertura Cuenta De Ahorros",
    eventCode: 2207,
    eventMnemonic: "FE_ACA2",
  },
  ACA101: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "ACA101",
    eventName: DEFAULT_ASSETS.GENERACION_OTP,
  },
  ACA102: {
    code: "ACA102",
    success: false,
    eventName: "Error en la Creación Cuenta",
    titleScreen: DEFAULT_ASSETS.ESTAMOS_PRESENTANDO_ALGUNOS_INCONVENIENTES,
    messageScreen: DEFAULT_ASSETS.INTENTALO_NUEVAMENTE,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_INVALID_CUSTOMER·SVG,
    showCode: true,
    eventCode: 3301,
    eventMnemonic: "FE_ACAError",
  },
  ACA103: {
    code: "ACA103",
    success: false,
    titleScreen: DEFAULT_ASSETS.NO_PODEMOS_ATENDER_LA_SOLICITUD,
    messageScreen: DEFAULT_ASSETS.NO_CUMPLE_CON_POLITICAS_REQUERIDAS,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_INVALID_CUSTOMER·SVG,
    showCode: true,
    eventName: "Error preguntas Filtro",
    motivo: "No cumple preguntas filtro",
    eventCode: 3302,
    eventMnemonic: "FE_ACAError",
  },
};
