import {
  DEFAULT_ASSETS,
  DEFAULT_ERROR_OPERATORS,
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
} from "../customer-status-code-websocket";

/* Aportes en Linea */
export const AST: {
  [key: string]: IStatusCodesWsError | IStatusCodesWsSuccess;
} = {
  AST101: {
    code: "AST101",
    eventName: DEFAULT_ASSETS.ASOCIACION_TARJETA,
    success: false,
    titleScreen: "Error al asociar la tarjeta a la cuenta de ahorros",
    messageScreen: "Por favor comunicarse con el área de producto",
    showCode: true,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_INVALID_CUSTOMER·SVG,
    eventCode: 3101,
    eventMnemonic: "FE_ASTError",
  },
  AST102: {
    code: "AST102",
    eventName: DEFAULT_ASSETS.ASOCIACION_TARJETA,
    success: false,
    titleScreen: "Error al asociar la tarjeta a la cuenta de ahorros",
    messageScreen: "Por favor comunicarse con el área de producto",
    showCode: true,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_INVALID_CUSTOMER·SVG,
    eventCode: 3102,
    eventMnemonic: "FE_ASTError",
  },
  AST301: {
    code: "AST301",
    success: true,
    eventName: DEFAULT_ASSETS.ASOCIACION_TARJETA,
    eventCode: 2216,
    eventMnemonic: "FE_AST1",
  },
  AST302: {
    code: "AST302",
    success: true,
    eventName: DEFAULT_ASSETS.ASOCIACION_TARJETA,
    eventCode: 2217,
    eventMnemonic: "FE_AST2",
  },
};
