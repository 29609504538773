<cdao-modal id="websocket-disconnect" [clickingAnywhereCloses]="false">
  <div class="content">

    <img src="assets/img/ca-invalid-customer.svg" alt="title" class="custom-error__img" loading="lazy" />

    <h3 class="custom-error__title"> Espéranos un momento</h3>

    <p class="custom-error__message">Estamos restableciendo la conexión; en breve podrás continuar con el proceso</p>

    <ng-lottie
      [options]="lottieConfig"
      width="100px"
      height="100px"
      >
    </ng-lottie>

    <p class="content__blue-text">Automáticamente la página se actualizará y volverás al paso en donde estabas</p>
  </div>
</cdao-modal>
