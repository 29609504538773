export const environment = {
  production: false,

  // ---------- ENDPOINTS ---------- //
  urlCustomerValidation:
    "https://0h6endapf5.execute-api.us-east-2.amazonaws.com/dev/v2/saving-account/process",
  urlAvailabilityValidation:
    "https://0h6endapf5.execute-api.us-east-2.amazonaws.com/dev/v2/saving-account/status",
  siteGoogleKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  requiredCaptcha: true,
  webSocketSavindAccount:
    "wss://ahorro-dev-ws-api-occidente.avaldigitallabs.com",
  officeAsociation:
    "https://0h6endapf5.execute-api.us-east-2.amazonaws.com/dev/v2/cities?residencesCities=false",
  urlimageBio:
    "https://0ut4sgnhm9.execute-api.us-east-2.amazonaws.com/dev/biometric/uploadImages",
  // Analytics
  apiGtwSM:
    "https://0ut4sgnhm9.execute-api.us-east-2.amazonaws.com/dev/auth/generateToken",
  kinesisStream: "bb-analytics-dev-ahorro-kns-stream",
  AdlAnalyticsAdlUrl:
    "https://analytics-dev-occidente.avaldigitallabs.com/event",
  cypherJsKey: "changeme",
  stage_name: "dev",

  // ---------- AZURE AUTHENTICATION ---------- //
  issuer: "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_Cxy2z3ztJ",
  redirectUri:
    "https://d1uc5usxnh19dy.cloudfront.net/cuenta-ahorro-digital/filter-questions",
  clientId: "61b3af4v5cms33ptcp8hhka1q9",
  scope: "email openid profile",
  postLogoutRedirectUri:
    "https://d1uc5usxnh19dy.cloudfront.net/cuenta-ahorro-digital/",

  // ---------- SALES FORCE    ---------- //
  salesForce:
    "https://0ut4sgnhm9.execute-api.us-east-2.amazonaws.com/dev/v2/authorizer-fsv",
  issuerFSV: "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_qFGKQko6Y",
  clientIdFSV: "25um515o355omib8ea7hfu6779",
};
