<div *ngIf="show">
  <div class="loader-bg">
    <div class="loader-container">
      <div class="animation">
        <ng-lottie 
          [options]="lottieConfig"
          (animCreated)="handleAnimation($event)" 
          width="150px"
          height="150px"
          >
        </ng-lottie>
      </div>
      <div class="loader-message text-center">
        <h2>Espera un momento</h2>
        <label *ngIf="progress === null">Cargando...</label>
        <div *ngIf="progress !== null && progress !== -1">
          <label>Cargando {{progress}}%</label>
          <cdao-progress-bar [value]="progress"></cdao-progress-bar>
        </div>
      </div>
    </div>
  </div>
</div>
