import {
  DEFAULT_ERROR_MESSAGE,
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
} from "../customer-status-code-websocket";

export const CRM: {
  [key: string]: IStatusCodesWsError | IStatusCodesWsSuccess;
} = {
  CRM001: {
    code: "CRM001",
    success: true,
    eventName: "Notificar Exito Creación Cliente CRM",
    eventCode: 2208,
    eventMnemonic: "FE_CRM",
  },
  CRM101: {
    ...DEFAULT_ERROR_MESSAGE,
    code: "CRM101",
    eventName: "Creación Cliente",
    motivo: "Falla en servicio creación cliente CRM",
    success: false,
  },
};
