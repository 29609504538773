import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "cdao-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.scss"],
})
export class ProgressBarComponent implements OnInit {
  private _value = 0;

  @ViewChild("dynamic") _dinamic: ElementRef<HTMLInputElement>;

  @Input()
  get value(): number {
    return this._value;
  }
  set value(value: number) {
    this._value = Math.round(value);
  }
  ngOnInit() {
    // do nothing.
  }

  get style() {
    return { width: `${this.value}%` };
  }
}
