import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import player from "lottie-web";
import { LottieModule } from "ngx-lottie";
import { ProgressBarComponentModule } from "../../../progress-bar/progress-bar.component.module";
import { LoaderComponent } from "./loader.component";

export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [LoaderComponent],
  imports: [
    CommonModule,
    ProgressBarComponentModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  exports: [LoaderComponent],
})
export class LoaderComponentModule {}
