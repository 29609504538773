import { STEP } from "src/app/commons/constants/global-constants";
import {
  DEFAULT_ASSETS,
  DEFAULT_ERROR_MESSAGE,
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
} from "../customer-status-code-websocket";

export const CDS: {
  [key: string]: IStatusCodesWsError | IStatusCodesWsSuccess;
} = {
  CDS001: {
    code: "CDS001",
    success: true,
    navigateTo: STEP.SECURE_DATA.URL,
    eventName: "Notificar Exito Consulta Dato Seguro",
    eventCode: 2212,
    eventMnemonic: "FE_CDS",
  },
  CDS002: {
    code: "CDS002",
    success: true,
    navigateTo: STEP.ACCOUNT_CONFIRM.URL,
    eventName: "Notificar Exito Crear Dato Seguro",
    eventCode: 2212,
    eventMnemonic: "FE_CDS",
  },
  CDS101: {
    code: "CDS101",
    success: true,
    eventName: "Error, consultando el dato seguro del cliente",
    eventCode: 2212,
    eventMnemonic: "FE_CDS",
  },
  CDS102: {
    code: "CDS102",
    success: true,
    eventName: "Error, en la creacion del dato seguro",
    navigateTo: STEP.ACCOUNT_CONFIRM.URL,
    eventCode: 2212,
    eventMnemonic: "FE_CDS",
  },
  CDS103: {
    code: "CDS103",
    success: true,
    eventName: "Error, en la creacion del dato seguro",
    navigateTo: STEP.ACCOUNT_CONFIRM.URL,
    eventCode: 2212,
    eventMnemonic: "FE_CDS",
  },
};
