import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";

export const AUTH_CONFIG: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.issuer,
  // URL of the SPA to redirect the user to after login
  redirectUri: environment.redirectUri,
  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: environment.clientId,
  // Scope for the permissions the client should request
  scope: environment.scope,
  // Response Type
  responseType: "code",
  showDebugInformation: true,
  // Others
  strictDiscoveryDocumentValidation: false,
  sessionChecksEnabled: false,
  postLogoutRedirectUri: environment.postLogoutRedirectUri,
  useSilentRefresh: true,
};

export const AUTH_CONFIG_FSV: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.issuerFSV,
  // URL of the SPA to redirect the user to after login
  redirectUri: environment.redirectUri,
  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: environment.clientIdFSV,
  // Scope for the permissions the client should request
  scope: environment.scope,
  // Response Type
  responseType: "code",
  showDebugInformation: true,
  // Others
  strictDiscoveryDocumentValidation: false,
  sessionChecksEnabled: false,
  postLogoutRedirectUri: environment.postLogoutRedirectUri,
  useSilentRefresh: false,
};
