import { IEventLog } from "../models/event-message";

export interface IEventDirectory {
  filterQuestions: IEventLog;
  accountType: IEventLog;
  accountTypeNomina: IEventLog;
  identity: IEventLog;
  errorIdentity: IEventLog;
  personalInformation1: IEventLog;
  personalInformation2: IEventLog;
  otpManagment: IEventLog;
  errorOtpManagment: IEventLog;
  deliveryCard: IEventLog;
  errorDeliveryCard: IEventLog;
  laboralData1: IEventLog;
  errorLaboralData1: IEventLog;
  laboralData2: IEventLog;
  errorLaboralData2: IEventLog;
  financialData: IEventLog;
  errorFinancialData: IEventLog;
  monthlyTransactions: IEventLog;
  manageAccount: IEventLog;
  errorManageAccount: IEventLog;
  validateAddress: IEventLog;
  errorValidateAddress: IEventLog;
  stepMachineError: IEventLog;
  stepMachineErrorNoMapper: IEventLog;
  officeAssociation: IEventLog;
  requestMonthlyTransacctions: IEventLog;

  logFsvAdvisorsOk: IEventLog;
  logFsvAdvisorsError: IEventLog;
  logFsvAddAdvisorOk: IEventLog;
  logFsvAddAdvisorError: IEventLog;
  logFsvErAdvisorOk: IEventLog;
  logFsvErAdvisorError: IEventLog;
  logFsvEdAdvisorOk: IEventLog;
  logFsvEdAdvisorError: IEventLog;
  logFsvEnableAdvisor: IEventLog;
  logFsvEnableAdvisorError: IEventLog;
  logFsvDisenableAdvisor: IEventLog;
  logFsvDisenableAdvisorError: IEventLog;
}

export const EVENT_DIRECTORY: IEventDirectory = {
  filterQuestions: {
    eventCode: 2001,
    eventMnemonic: "FE_FQ",
    eventName: "Filter Questions",
  },
  identity: {
    eventCode: 2002,
    eventMnemonic: "FE_CV",
    eventName: "Customer Validation",
  },
  errorIdentity: {
    eventCode: 3002,
    eventMnemonic: "FE_CVError",
    eventName: "Error Customer Validation",
  },

  personalInformation1: {
    eventCode: 2003,
    eventMnemonic: "FE_PI1",
    eventName: "Personal Information 1",
  },
  personalInformation2: {
    eventCode: 2004,
    eventMnemonic: "FE_PI2",
    eventName: "Personal Information 2",
  },
  laboralData1: {
    eventCode: 2005,
    eventMnemonic: "FE_LD1",
    eventName: "Laboral Data 1",
  },
  errorLaboralData1: {
    eventCode: 3005,
    eventMnemonic: "FE_LD1Error",
    eventName: "Laboral Data 1 error",
  },

  laboralData2: {
    eventCode: 2006,
    eventMnemonic: "FE_LD2",
    eventName: "Laboral Data 2",
  },
  errorLaboralData2: {
    eventCode: 3006,
    eventMnemonic: "FE_LD2Error",
    eventName: "Laboral Data 2 error",
  },
  financialData: {
    eventCode: 2007,
    eventMnemonic: "FE_FD",
    eventName: "Customer Financial Data",
  },
  errorFinancialData: {
    eventCode: 3007,
    eventMnemonic: "FE_FDError",
    eventName: "Customer Financial Data error",
  },

  monthlyTransactions: {
    eventCode: 2013,
    eventMnemonic: "FE_MT",
    eventName: "monthly transactions",
  },
  officeAssociation: {
    eventCode: 2212,
    eventMnemonic: "FE_OA",
    eventName: "Office associated to account",
  },
  manageAccount: {
    eventCode: 2008,
    eventMnemonic: "FE_MA",
    eventName: "Manage Account Options",
  },
  errorManageAccount: {
    eventCode: 3008,
    eventMnemonic: "FE_MAError",
    eventName: "Manage Account Options error",
  },
  deliveryCard: {
    eventCode: 2009,
    eventMnemonic: "FE_DC",
    eventName: "Delivery card",
  },
  errorDeliveryCard: {
    eventCode: 3009,
    eventMnemonic: "FE_DCError",
    eventName: "Delivery card error",
  },
  otpManagment: {
    eventCode: 2010,
    eventMnemonic: "FE_OTP",
    eventName: "One Time Passwords",
  },
  errorOtpManagment: {
    eventCode: 3010,
    eventMnemonic: "FE_ErrorOTP",
    eventName: "One Time Passwords Error",
  },

  validateAddress: {
    eventCode: 2011,
    eventMnemonic: "FE_VA",
    eventName: "Validar Direccion de envío",
  },
  errorValidateAddress: {
    eventCode: 3011,
    eventMnemonic: "FE_ErrorVA",
    eventName: "Error al validar Direccion de envío",
  },
  accountType: {
    eventCode: 2012,
    eventMnemonic: "FE_AT",
    eventName: "Seleccion Tipo de Cuenta",
  },
  accountTypeNomina: {
    eventCode: 2015,
    eventMnemonic: "FE_ATN",
    eventName: "Seleccion Tipo de Cuenta Nomina",
  },
  stepMachineError: {
    eventCode: 2500,
    eventMnemonic: "FE_EME",
    eventName: "Error en máquina de estados",
  },
  stepMachineErrorNoMapper: {
    eventCode: 2404,
    eventMnemonic: "FE_ENP",
    eventName: "Error no mapeado",
  },
  requestMonthlyTransacctions: {
    eventCode: 2219,
    eventMnemonic: "FE_SIF",
    eventName: "Solicitar Info Transacciones",
  },
  logFsvAdvisorsOk: {
    eventCode: 1001,
    eventMnemonic: "FSV_E",
    eventName: "Se evidencia listado de Usuarios",
  },
  logFsvAdvisorsError: {
    eventCode: 1002,
    eventMnemonic: "FSV_E",
    eventName: "Error, no se evidencia listado de Usuarios",
  },
  logFsvAddAdvisorOk: {
    eventCode: 1003,
    eventMnemonic: "FSV_E",
    eventName: "Creación de usuario correcta",
  },
  logFsvAddAdvisorError: {
    eventCode: 1004,
    eventMnemonic: "FSV_E",
    eventName: "Error en la creación de usuario",
  },
  logFsvErAdvisorOk: {
    eventCode: 1005,
    eventMnemonic: "FSV_E",
    eventName: "Eliminación de usuario correcta",
  },
  logFsvErAdvisorError: {
    eventCode: 1006,
    eventMnemonic: "FSV_E",
    eventName: "Error en la eliminación de usuario",
  },
  logFsvEdAdvisorOk: {
    eventCode: 1007,
    eventMnemonic: "FSV_E",
    eventName: "Se editó correctamente al usuario",
  },
  logFsvEdAdvisorError: {
    eventCode: 1008,
    eventMnemonic: "FSV_E",
    eventName: "Error, no se edito al usuario",
  },
  logFsvDisenableAdvisor: {
    eventCode: 1009,
    eventMnemonic: "FSV_E",
    eventName: "Se Deshabilito el usuario correctamente",
  },
  logFsvDisenableAdvisorError: {
    eventCode: 1010,
    eventMnemonic: "FSV_E",
    eventName: "Error al dehabilitar el usuario",
  },
  logFsvEnableAdvisor: {
    eventCode: 1011,
    eventMnemonic: "FSV_E",
    eventName: "Se habilito el usuario correctamente",
  },
  logFsvEnableAdvisorError: {
    eventCode: 1012,
    eventMnemonic: "FSV_E",
    eventName: "Error al habilitar el usuario",
  },
};
