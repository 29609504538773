import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
// import { JwksValidationHandler } from "angular-oauth2-oidc-jwks";
import { Observable, of } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";
import { SessionStorageCryptService } from "src/app/core/services/session-storage-crypt/session-storage-crypt.service";
import { isNullOrUndefined } from "util";
import { AUTH_CONFIG } from "../../../commons/constants/oauth.config";
import { IAdvisor } from "../../models/advisor";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public advisor: IAdvisor;
  private claims: any = null;

  constructor(
    public oauthService: OAuthService,
    public sessionStorageCrypt: SessionStorageCryptService,
  ) {
    this.configure();
  }

  configure() {
    this.oauthService.configure(AUTH_CONFIG);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  public login() {
    this.oauthService.logOut();
    this.oauthService.revokeTokenAndLogout();
    this.oauthService.initLoginFlow();
  }

  public logOut() {
    this.oauthService.logoutUrl = this.oauthService.loginUrl.replace(
      "oauth2/authorize",
      `logout?client_id=${this.oauthService.clientId}&logout_uri=${this.oauthService.postLogoutRedirectUri}`,
    );
    sessionStorage.clear();
    localStorage.clear();
    this.oauthService.logOut();
    this.oauthService.revokeTokenAndLogout();
  }

  public verifySession(): Observable<boolean> {
    return of(this.validateAuthToken()).pipe(
      mergeMap((isValidAuthToken) => this.getClaims(isValidAuthToken)),
      mergeMap((claimsExists) => this.setManagerInfo(claimsExists)),
    );
  }

  public getAdvisorData(): Observable<IAdvisor> {
    if (!this.advisor) {
      this.setAdvisorInfo();
    }
    return of(this.advisor);
  }

  private setAdvisorInfo() {
    this.claims = this.oauthService.getIdentityClaims();
    if (this.claims) {
      this.advisor = {
        email: this.claims.email,
        fullName: this.claims.name,
      };
    }
  }

  public validateAccessToken(): Observable<boolean> {
    return of(
      !!(
        this.oauthService.getAccessToken() &&
        this.oauthService.hasValidAccessToken()
      ),
    );
  }

  private validateAuthToken(): boolean {
    this.claims = this.oauthService.getIdentityClaims();
    return (
      !isNullOrUndefined(this.claims) &&
      !isNullOrUndefined(this.oauthService.getAccessToken())
    );
  }

  private getClaims(isValidAuthToken: boolean): Observable<boolean> {
    if (!isValidAuthToken) {
      return this.oauthService.events.pipe(
        filter(() => this.validateAuthToken()),
        mergeMap((_) => of(!isNullOrUndefined(this.claims))),
      );
    } else {
      return of(isValidAuthToken);
    }
  }

  private setManagerInfo(claimsExists: boolean): Observable<boolean> {
    if (claimsExists) {
      this.advisor = !this.advisor
        ? {
            fullName: this.claims.name,
            email: this.claims.email,
          }
        : this.advisor;
      return of(true);
    } else {
      of(false);
    }
  }
}
