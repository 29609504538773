import { Injectable } from "@angular/core";
import { CryptoService } from "../crypto/crypto.service";

@Injectable({
  providedIn: "root",
})
export class SessionStorageCryptService {
  constructor(private cryptoService: CryptoService) {}

  getItem(key: string) {
    const item = sessionStorage.getItem(key);
    return item ? this.cryptoService.decrypt(item) : item;
  }

  removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  setItem(key: string, value: string) {
    const valueCyphered = this.cryptoService.encrypt(value);
    sessionStorage.setItem(key, valueCyphered);
  }
}
