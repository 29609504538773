export enum FieldsName {
  /* General */
  motivo = "Motivo",
  /* Filter Questions */
  usPerson = "Responsable de impuestos en EEUU (U.S. Person) u otro país diferente a Colombia",
  foreignCurrency = "Realiza operaciones en moneda extranjera",
  stateResources = "Maneja recursos del estado",

  /* Account Type  */
  accountClass = "Tipo de cuenta a ser creada",

  /* Account Type Nomina */
  nominaAccount = "Tipo de cuenta para cuentas Nomina",

  /* Identity */
  firstName = "Primer nombre",
  middleName = "Nombre de en medio",
  surname = "Apellido",
  middleSurname = "Segundo Apellido",
  documentType = "tipo de documento",
  phone = "teléfono",
  email = "correo electrónico",
  identification = "id Number" /** */,
  digitalSignature = "Firma electrónica",
  acceptTerms = "Acepta los términos y condiciones",
  acceptTermsVersion = `version de los términos y condiciones`,

  /* OTP */

  /* Personal Information 1 */
  birthCountry = "Pais de nacimiento",
  birthCity = "Ciudad de nacimiento",
  birthDay = "Día de Nacimiento",
  expeditionCity = "Ciudad de Expedición",
  expeditionDate = "Fecha de Expedición",

  /* Personal Information 2 */
  gender = "Genero del Usuario",
  civilStatus = "Estado Civil",
  educationalLevel = "Nivel de Educacion",
  residenceCity = "Ciudad de Recidencia",
  residentialAddress = "Direccion de Recidencia",
  residentialComplement = "Complemento de Residencia",

  /* Laboral Data 1 */
  entryDate = "Fecha de Ingreso",
  occupation = "Ocupacion",
  job = "Trabajo",
  company = "Compañia",
  companyRole = "Role en la compañia",
  economicActivity = "Actividad económica de empresa",

  /* Laboral Data 2 */
  laboralCity = "Ciudad Laboral",
  laboralAdress = "Direccion de lugar de trabajo",
  complementAdress = "Complemento en direccion de trabajo",
  laboralPhone = "Telefono de lugar de Trabajo",

  /* Financial Data*/
  typeOfContract = "Tipo de contrato",
  monthlyIncome = "Ingresos mensuales",
  monthlyExpenses = "Gastos mensuales",
  assetValue = "Valor aproximado de activos",
  liabilitiesValue = "Valor aproximado de pasivos",
  otherIncome = "Otros ingresos",
  declareResources = "Declaración de origen de fondos",

  /* Transacciones mensuales */
  creditAmount = "Monto crédito",
  debitAmount = "Monto débito",

  /* Office Association */
  bankOffice = "Oficina associada a la cuenta",

  /* Manage Acount */
  withCard = "Solicitud de Tarjeta débito",

  /* Delivery Card */
  cardDelivery = "Lugar de envio de Tarjeta débito",

  /* Validate Address */
  addressOptions = "Opciones de dirección de envio a Tarjeta débito",
  eventCode = "Código de máquina de estados",
  eventDescription = "Descripción del código de evento",

  /* event name  key */
  keyEmail = "Advisord  Email",
  keyName = "Advisord Nombre aasesor",
  keyNameEmail = "Advisord Email Name",
}

export type FileNameType = keyof typeof FieldsName;
