import { environment } from "../../../environments/environment";

export const OTP_MANAGEMENT = {
  TIME_LIVE_OTP_MINUTES: 2,
  TIME_SHOW_MESSAGE_RESEND_OTP_MINUTES: 0.5,
  OTP_SIZE: 8,
  ATTEMPTS_GENERATE_TOKEN: 4,
  ATTEMPTS_VALIDATE_TOKEN: 2,
  COUNT_GENERATE: 0,
  GENERATE_VALID: "0",
  LIMIT_ERROR: 2,
};

export const DEFAULT_MESSAGE_ERROR = {
  DEFAULT_ERROR_TITLE:
    "Gracias por tu interés en nuestra Cuenta de Ahorros Digital, no es posible continuar con tu solicitud.",
  DEFAULT_ERROR_MESSAGE:
    "No es posible atender tu solicitud por este canal. Acércate a una de nuestras oficinas para más información.",
  DEFAULT_ERROR_TYPE: "technicalError",
  DEFAULT_ERROR_CODE: "011",
};

export const CONSTANTS = { HTTP_STATUS: "status" };

export const HTTP_STATUS_CODES = {
  BUSINESS: 406,
  UNAUTHORIZED: 401,
};

export const STEP = {
  LANDING: {
    URL: "/cuenta-ahorro-digital/",
  },
  FILTER_QUESTIONS: {
    NUMBER_STEP: 1,
    URL: "filter-questions",
  },
  IDENTITY: {
    NUMBER_STEP: 1,
    URL: "identity",
  },
  OTP: {
    NUMBER_STEP: 1,
    URL: "otp",
  },
  ACCOUNT_TYPE: {
    NUMBER_STEP: 1,
    URL: "account-type",
  },
  ACCOUNT_TYPE_NOMINA: {
    NUMBER_STEP: 1,
    URL: "account-type-nomina",
  },
  ACCOUNT_TYPE_DETAILS: {
    NUMBER_STEP: 1,
    URL: "account-type-details",
  },
  ACCOUNT_AGREEMENT: {
    NUMBER_STEP: 1,
    URL: "account-agreement",
  },
  ACCOUNT_TYPE_AGREEMENT: {
    NUMBER_STEP: 1,
    URL: "account-type-agreement",
  },
  PERSONAL_INFO: {
    NUMBER_STEP: 2,
    URL: "personal-info",
  },
  PERSONAL_INFO_2: {
    NUMBER_STEP: 2,
    URL: "personal-information-2",
  },
  LABOR_DATA_1: {
    NUMBER_STEP: 2,
    URL: "labor-data-1",
  },
  LABOR_DATA_2: {
    URL: "labor-data-2",
    NUMBER_STEP: 2,
  },
  FINANCIAL_DATA: {
    NUMBER_STEP: 2,
    URL: "financial-data",
  },
  MONTHLY_TRANSACTIONS: {
    NUMBER_STEP: 2,
    URL: "monthly_transactions",
  },
  OFFICE_ASSOCIATION: {
    NUMBER_STEP: 2,
    URL: "office-association",
  },
  VALIDATE_ADDRESS: {
    NUMBER_STEP: 2,
    URL: "validate-adress",
  },
  DELIVERY_CARD: {
    NUMBER_STEP: 3,
    URL: "delivery-card",
  },
  MANAGE_ACCOUNT: {
    NUMBER_STEP: 3,
    URL: "manage-account",
  },
  ACCOUNT_CONFIRM: {
    NUMBER_STEP: 3,
    URL: "account-confirm",
  },
  CUSTOM_ERRORS: {
    URL: "customs-error",
  },
  SECURE_DATA: {
    NUMBER_STEP: 3,
    URL: "secure-data",
  },
  CARD_DELIVERY: {
    NUMBER_STEP: 3,
    URL: "card-delivery",
  },
  BIOMETRIC: {
    URL: "home-biometric",
  },
  OVERFLOWS_BIOMETRIC: {
    URL: "overflows-biometric",
  },
  BIOMETRIC_DOCUMENT_VALIDATION: {
    URL: "document-validation-biometric",
  },
  BIOMETRIC_CONFIRM_PHOTO_DOCUMENT: {
    URL: "confirmation-front-photo-document",
  },
  FACE_VALIDATION: {
    URL: "face-calidation",
  },
  FACE_PHOTO_CONFIRMATION: {
    URL: "face-photo-confirmation",
  },
};

export const ANALYTICS_EVENT_CONFIG = {
  version: "3.0",
  application: "BOCC-CUENTAAHORRO",
  channel: "self-managed",
  sessionId: "_ga",
  clientIdType: "CC",
  kinesisStream: environment.kinesisStream,
};

export const AGE_PARAMETERS = {
  MIN_AGE: 18,
  MAX_AGE: 99,
};

export const SARLAFT_INFORMATION = {
  NOT_FOREIGN_CURRENCY: "No",
  NOT_STATE_RESOURCES: "No",
  NOT_US_PERSON: "No",
};

export const DELIVERY_CARD = {
  DELIVERY: "home",
  OFFICE: "ofi",
  NO_CARD: "no_card",
  DESCRIPTION_DELIVERY: "En casa",
  DESCRIPTION_OFFICE: "En oficina",
  DESCRIPTION_NO_CARD: "Sin tarjeta débito",
  SHIPPING_TIME_DELIVERY: "Tiempo estimado de entrega entre 3 a 5 días hábiles",
  SHIPPING_TIME_OFFICE:
    "Podras recoger tu tarjeta débito, en cualquier oficina a nivel nacional",
  SHIPPING_TIME_NO_CARD:
    "Accede, usa y retira tu dinero a través de nuestros canales electrónicos.",
};

export const UserGuideUrl =
  "https://www.bancodeoccidente.com.co/wps/portal/banco-de-occidente/bancodeoccidente/para-personas/cuentas/cuenta-de-ahorros";

export const PROTECTED_URLS = [
  environment.urlCustomerValidation,
  environment.urlAvailabilityValidation,
  environment.officeAsociation,
  environment.salesForce,
];

export const IGNORE_LOADER_INTERCEPTOR_URLS = [""];

export const IDENTITY = {
  AUTOCOMPLETE_REGEXP: /^[a-zA-Z - \u00F1\u00D1']+/,
  INITIAL_SEARCH_LENGTH: 3,
  CITY_BOGOTA: "BOGOTA, D.C.",
  CITY_BOGOTA_V2: "BOGOTA",
};

export const TEALIUM_CONFIG = {
  account: "adl",
  profile: "occidente",
};

export const REG_EXP_DIACRITIC = /([^n]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi;

export const REG_EXP_DIACRITIC_WITH_N = {
  n: "ñ|Ñ",
};

export const UTAG_EVENT_CATEGORY = "Cta. Ahorros";

// Lista de reintentos de peticiones
export const LIST_RETRIES_URLS = [
  { url: environment.urlAvailabilityValidation, retries: 1, time: 1000 },
];

export const ACCOUNT_CLASS_IDS = {
  OCCIDIA_NORMAL: { ID: "1", NAME: "occidia" },
  PENSIONADOS: { ID: "2", NAME: "pension" },
  NOMINA_PlATA: { ID: "3", NAME: "nomina-plata" },
  NOMINA_ORO: { ID: "4", NAME: "nomina-oro" },
  NOMINA_PLATINO: { ID: "5", NAME: "nomina-platino" },
  EMPLEADO: { ID: "6", NAME: "empleado" },
  CONVENIO_ORO: { ID: "7", NAME: "nomina-convenio-oro" },
  CONVENIO_PLATINO: { ID: "8", NAME: "nomina-convenio-platino" },
  MONOPRODUCTO: { ID: "9", NAME: "cuenta-monoproducto" },
  DIFERENTE: { ID: "0", NAME: "Diferente" },
};

export const WEB_SOCKET_CONSTANTS = {
  LOADING_SCREENS_TIME_MS: 3 * 60 * 1000,
  TIME_OF_RECONNECT_MS: 1000,
  MAX_ATTEMPTS_RECONNECT: 5,
  TIME_OF_PING: 1000 * 60,
};

export const RETRY_STRATEGY_CONSTANTS = {
  MAX_RETRY_ATTEMPTS: 5,
  SCALING_DURATION: 1000,
  EXCLUDED_STATUS_CODES: [],
};

export const TEXT_MODAL_DATASAFE = {
  CDS101: {
    text:
      "El dato seguro no pudo ser consultado con éxito, debe crearse en oficina",
    icon: "imgalertDSe",
  },
  CDS102: {
    text:
      "El dato seguro no pudo ser creado con éxito, debe crearse en oficina",
    icon: "imgalertDSe",
  },
  CDS103: {
    text: "Tiempo agotado para crear dato seguro",
    icon: "imgalertDSe",
  },
  CDS002: { text: "El dato seguro fue creado con éxito", icon: "imgalertDSs" },
};

export const TEXT_OVERFLOWSBIO = {
  PBF106: {
    code: "PBF106",
    success: false,
    flagC: "2",
    title1: "Lo sentimos,",
    title2: "no ha sido posible",
    title3: "autenticar al cliente",
    text1: "El usuario no cumple con los",
    text2: "requisitos de la biometría facial",
    textAlert: "",
    icon: "assets/img/warnbc.svg",
    infoButton: "",
  },
  PBF107: {
    code: "PBF107",
    success: false,
    flagC: "2",
    title1: "Lo sentimos,",
    title2: "no ha sido posible",
    title3: "autenticar al cliente",
    text1: "El usuario no cumple con los",
    text2: "requisitos de la biometría facial",
    textAlert: "",
    icon: "assets/img/warnbc.svg",
  },
  PBF108: {
    code: "PBF108",
    success: false,
    flagC: "2",
    title1: "Lo sentimos,",
    title2: "no ha sido posible",
    title3: "autenticar al cliente",
    text1: "El usuario no cumple con los",
    text2: "requisitos de la biometría facial",
    textAlert: "",
    icon: "assets/img/warnbc.svg",
  },
  PBF109: {
    code: "PBF109",
    success: false,
    flagC: "2",
    title1: "Lo sentimos,",
    title2: "no ha sido posible",
    title3: "autenticar al cliente",
    text1: "El usuario no cumple con los",
    text2: "requisitos de la biometría facial",
    textAlert: "",
    icon: "assets/img/warnbc.svg",
  },
  PBF110: {
    code: "PBF110",
    success: false,
    flagC: "2",
    title1: "Lo sentimos,",
    title2: "no ha sido posible",
    title3: "autenticar al cliente",
    text1: "El usuario no cumple con los",
    text2: "requisitos de la biometría facial",
    textAlert: "",
    icon: "assets/img/warnbc.svg",
  },
  PBF111: {
    code: "PBF111",
    success: false,
    flagC: "2",
    title1: "Lo sentimos,",
    title2: "no ha sido posible",
    title3: "autenticar al cliente",
    text1: "El usuario no cumple con los",
    text2: "requisitos de la biometría facial",
    textAlert: "",
    icon: "assets/img/warnbc.svg",
  },
  PBF112: {
    code: "PBF112",
    success: false,
    flagC: "2",
    title1: "Lo sentimos,",
    title2: "no ha sido posible",
    title3: "autenticar al cliente",
    text1: "El usuario no cumple con los",
    text2: "requisitos de la biometría facial",
    textAlert: "",
    icon: "assets/img/warnbc.svg",
  },
  PBF113: {
    code: "PBF113",
    success: false,
    flagC: "2",
    title1: "Lo sentimos,",
    title2: "no ha sido posible",
    title3: "autenticar al cliente",
    text1: "El usuario no cumple con los",
    text2: "requisitos de la biometría facial",
    textAlert: "",
    icon: "assets/img/warnbc.svg",
  },
  PBF114: {
    code: "PBF114",
    success: false,
    flagC: "2",
    title1: "Lo sentimos,",
    title2: "no ha sido posible",
    title3: "autenticar al cliente",
    text1: "El usuario no cumple con los",
    text2: "requisitos de la biometría facial",
    textAlert: "",
    icon: "assets/img/warnbc.svg",
  },
  PBF115: {
    code: "PBF115",
    success: false,
    flagC: "2",
    title1: "Lo sentimos,",
    title2: "no ha sido posible",
    title3: "autenticar al cliente",
    text1: "El usuario no cumple con los",
    text2: "requisitos de la biometría facial",
    textAlert: "",
    icon: "assets/img/warnbc.svg",
  },
  PBF101: {
    code: "PBF101",
    success: false,
    flagC: "2",
    title1: "Lo sentimos,",
    title2: "no ha sido posible",
    title3: "autenticar al cliente",
    text1: "Presentamos problemas técnicos",
    text2: "en la biometría facial",
    textAlert: "",
    icon: "assets/img/optbc.svg",
  },
  PBF104: {
    code: "PBF104",
    success: false,
    flagC: "2",
    title1: "Lo sentimos,",
    title2: "no ha sido posible",
    title3: "autenticar al cliente",
    text1: "Presentamos problemas técnicos",
    text2: "en la biometría facial",
    textAlert: "",
    icon: "assets/img/optbc.svg",
  },
  PBF105: {
    code: "PBF105",
    success: false,
    flagC: "2",
    title1: "Lo sentimos,",
    title2: "no ha sido posible",
    title3: "autenticar al cliente",
    text1: "Presentamos problemas técnicos",
    text2: "en la biometría facial",
    textAlert: "",
    icon: "assets/img/optbc.svg",
  },
  PBF103: {
    code: "PBF103",
    success: false,
    flagC: "1",
    title1: "La conexión caducó",
    title2: "",
    title3: "2",
    text1: "Has excedido el tiempo máximo",
    text2: "de inactividad en la solicitud",
    textAlert: "",
    icon: "assets/img/slowbc.svg",
  },
  PBF102: {
    code: "PBF102",
    success: false,
    flagC: "2",
    title1: "Ya existe ",
    title2: "un proceso ",
    title3: "biométrico en curso",
    text1: "Para continuar reintenta la ",
    text2: "solicitud más tarde",
    textAlert: "",
    icon: "assets/img/warnbc.svg",
  },
  PBF003: {
    code: "PBF003",
    success: true,
    flagC: "1",
    title1: "¡La biometría facial",
    title2: "ha sido exitosa!",
    title3: "",
    text1: "Las fotografías coinciden con los",
    text2: "datos y el rostro del cliente.",
    textAlert: "1",
    icon: "assets/img/checkbc.svg",
  },
  PBF120: {
    code: "PBF120",
    success: true,
    flagC: "1",
    title1: "La conexión caducó",
    title2: "",
    title3: "2",
    text1: "Has excedido el tiempo máximo",
    text2: "de inactividad en la solicitud",
    textAlert: "",
    icon: "assets/img/slowbc.svg",
  },
};
