import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { FormMemoryStorageService } from "@avaldigitallabs/adl-core";
import { from, Observable, pipe } from "rxjs";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";
import { filter, map, switchMap, takeWhile } from "rxjs/operators";
import { STEP } from "src/app/commons/constants/global-constants";
import { LoaderService } from "src/app/core/http/interceptors/loader/loader.service";
import {
  IResponseBodyOffice,
  IWSStateMachineResponse,
  IWSStateMachineResponseError,
} from "src/app/modules/identity/models/customer-validation/customer-validation-response";
import { STATUS_CODES_WS } from "../../models/status-codes";
import { SessionStorageCryptService } from "../../services/session-storage-crypt/session-storage-crypt.service";
import { WebSocketService } from "../../web-socket/services/web-socket.service";

const doWhile = <T>(shouldContinue: (a: T) => boolean) => {
  return pipe(
    switchMap((data: T) =>
      from([
        { data, continue: true },
        { data, continue: shouldContinue(data), exclude: true },
      ]),
    ),
    takeWhile((message) => message.continue),
    filter((message) => !message.exclude),
    map((message) => message.data),
  );
};
@Injectable({
  providedIn: "root",
})
export class WebSocketController {
  constructor(
    public router: Router,
    private fs: FormMemoryStorageService,
    private loader: LoaderService,
    public webSocketService: WebSocketService,
    private sessionStorageCrypt: SessionStorageCryptService,
  ) {}

  connect(): Observable<
    IWSStateMachineResponse | IWSStateMachineResponseError
  > {
    return new Observable((subscriber) => {
      (this.webSocketService.webSocketStarted
        ? this.webSocketService.webSocketState
        : this.webSocketService.initWebSocket(
            this.fs.form.get("basicData.processId")?.value,
          )
      )
        .pipe(doWhile((wsResponse) => !!!this.getUrl(wsResponse)))
        .subscribe({
          next: (wsResponse: IWSStateMachineResponse) => {
            subscriber.next(wsResponse);
            this.actions(wsResponse);
          },
          error: (error: IWSStateMachineResponseError) => {
            subscriber.error(error);
          },
          complete: () => {
            subscriber.complete();
          },
        });
    });
  }

  actions(wsResponse: IWSStateMachineResponse) {
    switch (wsResponse.data?.statusCode) {
      case STATUS_CODES_WS.DIF001.code:
        console.log("wsResponse.data.messageBody----", wsResponse.data);
        this.fs.form
          .get("basicData.clientAddress")
          .setValue(wsResponse.data.messageBody);
        this.fs.form.get("basicData.isClient").setValue(true);
        break;
      case STATUS_CODES_WS.ACA001.code:
        this.fs.form
          .get("basicData.accountNumber")
          .setValue(wsResponse.data.accountNumber);
        break;
      case STATUS_CODES_WS.AST302.code:
      case STATUS_CODES_WS.AST301.code:
        this.fs.form
          .get("basicData.cardAssociationError")
          .setValue(
            "No se asoció la tarjeta, por favor comunícate con soporte",
          );
        break;
      case STATUS_CODES_WS.CRM001.code:
        this.loader.progress.next(25);
        break;
      case STATUS_CODES_WS.ACA002.code:
        this.loader.progress.next(50);
        break;
      case STATUS_CODES_WS.ATD001.code:
        this.loader.progress.next(75);
        break;
      case STATUS_CODES_WS.CDP001.code:
        this.router.navigateByUrl(STEP.FILTER_QUESTIONS.URL);
        break;
      case STATUS_CODES_WS.OFI001.code:
        break;
      case STATUS_CODES_WS.AEL102.code:
        this.loader.progress.next(65);
        break;
      case STATUS_CODES_WS.MRG102.code:
        this.loader.progress.next(80);
        break;
      case STATUS_CODES_WS.VLE001.code:
        this.fs.form.get("basicData.email_validation").setValue(true);
        break;
      case STATUS_CODES_WS.SOI102.code:
        this.loader.progress.next(95);
        break;
      /* Proceso de carga */
      case STATUS_CODES_WS.PBF002.code:
        this.loader.show();
        this.loader.progress.next(70);
        break;
      case STATUS_CODES_WS.PBF004.code:
        this.loader.progress.next(80);
        break;
      /* EXITO BIOMETRIA */
      case STATUS_CODES_WS.PBF003.code:
        this.loader.progress.next(90);
        this.sessionStorageCrypt.setItem("pbf3", "PBF003");
        break;
      case STATUS_CODES_WS.CDS001.code:
        this.fs.form.get("basicData.email").setValue(wsResponse.data.email);
        this.fs.form
          .get("basicData.phone")
          .setValue(wsResponse.data.numberPhone);
        this.fs.form.get("basicData.flag").setValue(wsResponse.data.dataSafe);
        this.fs.form
          .get("basicData.accountNumber")
          .setValue(wsResponse.data.accountNumber);
        this.loader.progress.next(80);
        break;
      case STATUS_CODES_WS.CDS002.code:
      case STATUS_CODES_WS.CDS101.code:
      case STATUS_CODES_WS.CDS102.code:
      case STATUS_CODES_WS.CDS103.code:
        console.log("controler wensocket---", wsResponse.data.accountNumber);
        this.fs.form
          .get("basicData.accountNumber")
          .setValue(wsResponse.data.accountNumber);
        this.fs.form
          .get("basicData.eventDataSafe")
          .setValue(wsResponse.data?.statusCode);
        break;
      case STATUS_CODES_WS.OTP001.code:
        this.sessionStorageCrypt.setItem(
          "send-type-otp",
          wsResponse.data.sendType,
        );
        break;
      case STATUS_CODES_WS.TIF001.code:
        this.sessionStorageCrypt.setItem("banderaTif", "TIF001");
        this.router.navigateByUrl(STEP.CARD_DELIVERY.URL);
        break;
      case STATUS_CODES_WS.PBF001.code:
        console.log("controler wensocket BIOMETRIA---", wsResponse.data);
        sessionStorage.setItem("data-bf", JSON.stringify(wsResponse.data));
        sessionStorage.setItem("data-bckbf", JSON.stringify(wsResponse.data));
        break;
      case STATUS_CODES_WS.PBF005.code:
        this.loader.progress.next(50);
        sessionStorage.setItem("data-bckbf", JSON.stringify(wsResponse.data));
        break;
      case STATUS_CODES_WS.PBF006.code:
        this.loader.progress.next(60);
        sessionStorage.setItem("data-bckbf", JSON.stringify(wsResponse.data));
        break;
    }
    const url = this.getUrl(wsResponse);
    if (url) {
      this.router.navigateByUrl(url);
      this.loader.hide();
    }

    if (wsResponse.data?.TaskToken) {
      this.fs.form
        .get("basicData.taskToken")
        .setValue(wsResponse.data.TaskToken);
    }
  }

  private getUrl(wsResponse: IWSStateMachineResponse) {
    return STATUS_CODES_WS[wsResponse.data?.statusCode]?.navigateTo;
  }
}
