import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CardComponentModule } from "../../card/card.component.module";
import { AlertCardComponent } from "./alert-card.component";

@NgModule({
  declarations: [AlertCardComponent],
  exports: [AlertCardComponent],
  imports: [CommonModule, CardComponentModule],
})
export class AlertCardModule {}
