import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class IpService {
  private http: HttpClient;
  private apiGtwSM = environment.apiGtwSM;
  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  public getPublicIp(): Observable<any> {
    return this.http
      .post<any>(environment.apiGtwSM, {
        action: "getIP",
      })
      .pipe(map((response) => response?.body?.ip));
  }
}
