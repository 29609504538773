import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { iif, Observable, of, throwError } from "rxjs";
import { catchError, concatMap, delay, retryWhen } from "rxjs/operators";
import {
  DEFAULT_MESSAGE_ERROR,
  LIST_RETRIES_URLS,
  STEP,
} from "src/app/commons/constants/global-constants";
import { AlertProvider } from "src/app/core/alert/alert-provider/alert.provider";
import { STATUS_CODES_WS } from "src/app/core/models/status-codes";
import { WebSocketController } from "src/app/core/web-socket/controllers/web-socket-controller.service";
import {
  IResponsePatchError,
  IWSStateMachineResponse,
} from "src/app/modules/identity/models/customer-validation/customer-validation-response";

@Injectable({
  providedIn: "root",
})
export class InterceptorError implements HttpInterceptor {
  constructor(
    private router: Router,
    private alertProvider: AlertProvider,
    private webSocketController: WebSocketController,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retryWhen((errors) => {
        const RETRIES_URLS = LIST_RETRIES_URLS.find(
          (list) => list.url === req.url,
        );
        return errors.pipe(
          concatMap((e, i) => {
            return iif(
              () => !!RETRIES_URLS && i < RETRIES_URLS.retries,
              of(e).pipe(delay(RETRIES_URLS?.time ?? 100)),
              throwError(e),
            );
          }),
        );
      }),

      catchError((error: IResponsePatchError) => {
        console.log();
        const HTTP_ACTIONS_DIRECTORY = {
          400: () => {
            this.alertProvider.pushAlert({
              type: "warning",
              title: "Bad Request",
              description: "Revisa la información datos invalidos",
            });
          },
          406: () => {
            this.alertProvider.pushAlert({
              type: "success",
              title: "Sincronización",
              description: `Con la pantalla ${error.error.statusCode}`,
            });
            const wsResponse: IWSStateMachineResponse = {
              data: { ...error.error },
            };
            this.webSocketController.actions(wsResponse);
          },
          408: () => {
            this.router.navigateByUrl(
              `${STEP.CUSTOM_ERRORS.URL}/${STATUS_CODES_WS.INC408.code}`,
            );
          },
          409: () => {
            this.alertProvider.pushAlert({
              type: "danger",
              title: "Error",
              description: `Error creando el usuario - ${error.error.body.replace(
                /^.*:([^:]+)$/,
                "$1",
              )}`,
            });
          },
          default: () => {
            console.error("default error");
            this.router.navigateByUrl(
              `${STEP.CUSTOM_ERRORS.URL}/${DEFAULT_MESSAGE_ERROR.DEFAULT_ERROR_CODE}`,
            );
          },
        };

        const errorEjecuted =
          HTTP_ACTIONS_DIRECTORY[error.status] ||
          HTTP_ACTIONS_DIRECTORY.default;
        errorEjecuted();

        throw error;
      }),
    );
  }
}
