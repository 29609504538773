import {
  animate,
  group,
  query,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { IAlertInformation } from "../alert-card/alert-card.component";
import { AlertProvider } from "../alert-provider/alert.provider";
@Component({
  selector: "cdao-alert-container",
  templateUrl: "./alert-container.component.html",
  styleUrls: ["./alert-container.component.scss"],
  animations: [
    trigger("bounce", [
      transition("* <=> *", [
        query(
          ":enter, :leave",
          style({ position: "absolute", width: "100%" }),
          {
            optional: true,
          },
        ),
        query(":enter", style({ transform: "translateY(30rem)", opacity: 0 }), {
          optional: true,
        }),
        group([
          query(
            ":leave",
            [
              style({ transform: "translateY(-8rem)" }),
              animate(
                "1s cubic-bezier(0.35, 0, 0.25, 1)",
                style({ transform: "translateY(30rem)", opacity: 0 }),
              ),
            ],
            { optional: true },
          ),
          query(
            ":enter",
            [
              animate(
                "1s cubic-bezier(0.35, 0, 0.25, 1)",
                style({ transform: "translateY(-8rem)", opacity: 1 }),
              ),
            ],
            { optional: true, delay: "300ms" },
          ),
        ]),
      ]),
    ]),
  ],
})
export class AlertContainerComponent implements OnInit {
  private notificationList: IAlertInformation[] = [];

  constructor(private alertProvider: AlertProvider) {}
  private pushAlert(alert: IAlertInformation) {
    this.notificationList.push(alert);
  }

  ngOnInit() {
    this.alertProvider.pushAlertEmitter.subscribe(
      (alert: IAlertInformation) => {
        this.pushAlert(alert);
      },
    );
  }

  onRemoveAlert(index) {
    this.notificationList.splice(index, 1);
  }

  get getNotificationList() {
    return this.notificationList;
  }
}
