import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RouteLineTimeService {
  private step = 1;
  public changeStep = new Subject<number>();

  get stepActive(): number {
    return this.step;
  }

  public nextStep(step: number): void {
    this.step = step;
    this.changeStep.next(step);
  }

  public currentStep(step: number): void {
    this.step = step;
    this.changeStep.next(step);
  }
}
