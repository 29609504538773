import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { FormMemoryStorageService } from "@avaldigitallabs/adl-core";
import { of, Subject, throwError } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import {
  STEP,
  WEB_SOCKET_CONSTANTS,
} from "src/app/commons/constants/global-constants";
import {
  IWSStateMachineResponse,
  IWSStateMachineResponseError,
} from "src/app/modules/identity/models/customer-validation/customer-validation-response";
import { environment } from "src/environments/environment";

import { AnalyticsDispatcherService } from "../../analytics-logger/services/analytics-logger/analytics-dispatcher.service";
import { AnalyticsLoggerService } from "../../analytics-logger/services/analytics-logger/analytics-logger.service";
import { LoaderService } from "../../http/interceptors/loader/loader.service";
import { ModalService } from "../../modal/service/modal.service";
import { SessionStorageCryptService } from "../../services/session-storage-crypt/session-storage-crypt.service";

import { WebSocketValidate } from "./web-socket-validate.service";
@Injectable({
  providedIn: "root",
})
export class WebSocketService {
  constructor(
    protected fs: FormMemoryStorageService,
    private webSocketValidate: WebSocketValidate,
    private analyticsLogger: AnalyticsLoggerService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private router: Router,
    private analyticsDispatcher: AnalyticsDispatcherService,
    private sessionStorageCrypt: SessionStorageCryptService,
  ) {
    this.loadScreenTimer();
  }
  webSocketState: Subject<
    IWSStateMachineResponse | IWSStateMachineResponseError
  >;
  webSocketStarted = false;
  webSocketEnded = false;
  private maxAttempts = WEB_SOCKET_CONSTANTS.MAX_ATTEMPTS_RECONNECT;
  private attempts = 0;

  webSocketDirect: WebSocketSubject<
    IWSStateMachineResponse | { action: "ping" | "pong" }
  >;

  timeoutLoader: any;
  timeoutModal: any;
  intervalPing: any;

  private executePing() {
    if (this.intervalPing) {
      clearTimeout(this.intervalPing);
    }
    this.intervalPing = setInterval(() => {
      this.webSocketEnded
        ? clearTimeout(this.intervalPing)
        : this.webSocketDirect.next({ action: "ping" });
    }, WEB_SOCKET_CONSTANTS.TIME_OF_PING);
  }

  private WebSocketConnectionSuccessful() {
    this.executePing();
    this.webSocketStarted = true;
  }

  private prepareWebSocket(
    process_id: string,
    successfulConnection?: () => void,
    connectionClosed?: () => void,
  ) {
    const url = `${environment.webSocketSavindAccount}?processId=${process_id}`;
    const openObserver = {
      next: () => {
        this.WebSocketConnectionSuccessful();
        if (successfulConnection) {
          successfulConnection();
        }
      },
    };
    const closeObserver = {
      next: () => {
        if (connectionClosed) {
          connectionClosed();
        }
      },
    };
    return { url, openObserver, closeObserver };
  }

  private processWebsocketData(respWebSocket: IWSStateMachineResponse) {
    if (respWebSocket.pid_response) {
      return of(respWebSocket);
    } else if (this.webSocketValidate.validateWsResponse(respWebSocket)) {
      if (respWebSocket.data.TaskToken) {
        this.fs.form
          .get("basicData.taskToken")
          .setValue(respWebSocket.data.TaskToken);
      }
      return of(respWebSocket);
    } else {
      this.webSocketEnded = true;
      return throwError({
        message: "error: webSocket code: " + respWebSocket.data?.statusCode,
        ...respWebSocket,
      });
    }
  }

  public initWebSocket(
    process_id: string,
    successfulConnection?: () => void,
    connectionClosed?: () => void,
  ): Subject<IWSStateMachineResponse> {
    this.webSocketState = new Subject();
    this.webSocketDirect = webSocket<IWSStateMachineResponse>(
      this.prepareWebSocket(process_id, successfulConnection, connectionClosed),
    );
    this.webSocketDirect
      .pipe(
        mergeMap((respWebSocket: IWSStateMachineResponse) =>
          this.processWebsocketData(respWebSocket),
        ),
      )
      .subscribe({
        next: (respWebSocket) => {
          if (respWebSocket.data.statusCode === "TIF001") {
            this.sessionStorageCrypt.setItem("banderaTif", "TIF001");
          }
          this.analyticsDispatcher.logWebSocketEvent(
            respWebSocket.data.statusCode,
            true,
          );
          this.webSocketState.next(respWebSocket);
        },
        error: (error) => {
          this.analyticsDispatcher.logWebSocketEvent(
            error.data?.statusCode,
            false,
          );
          this.endConnection();
          this.webSocketState.error(error);
        },
        complete: () => {
          this.endConnection();
        },
      });
    return this.webSocketState;
  }

  private closeSubscribers() {
    if (this.webSocketState?.observers?.length > 0) {
      this.loaderService.hide();
      setTimeout(() => {
        this.webSocketState.observers.forEach((observer) => {
          observer.error({
            data: { statusCode: "ERD102" },
            message: "Connection closed",
          });
        });
      }, 100);
      return true;
    } else {
      return false;
    }
  }

  private reconnectWebSocket() {
    if (
      this.webSocketStarted &&
      !this.webSocketEnded &&
      this.attempts < this.maxAttempts
    ) {
      this.webSocketStarted = false;
      this.modalService.open("websocket-disconnect");
      setTimeout(() => {
        this.loaderService.hide();
        console.warn("try to reconnect webSocket");
        this.attempts++;
        const webSocketConnection = this.initWebSocket(
          this.fs.form.get("basicData.processId").value,
          () => {
            this.modalService.close("websocket-disconnect");
            this.attempts = 0;
            webSocketConnection.unsubscribe();
          },
        ).subscribe();
      }, WEB_SOCKET_CONSTANTS.TIME_OF_RECONNECT_MS * (this.attempts * 2 + 1));
    } else if (this.attempts >= this.maxAttempts) {
      this.modalService.close("websocket-disconnect");
      this.router.navigateByUrl(`${STEP.CUSTOM_ERRORS.URL}/ERD101`);
    }
  }

  private endConnection() {
    console.warn("ws disconnected");
    if (!this.closeSubscribers()) {
      this.webSocketState.complete();
    }
    this.reconnectWebSocket();
  }

  private closeAndReconnect() {
    if (this.webSocketDirect) {
      this.webSocketDirect.complete();
    }
    this.reconnectWebSocket();
  }

  private modalScreenTimer() {
    this.modalService.event.subscribe((event) => {
      if (event.id === "websocket-disconnect") {
        switch (event.state) {
          case "open":
            if (!this.timeoutModal) {
              this.timeoutModal = setTimeout(() => {
                this.modalService.close("websocket-disconnect");
                this.timeoutModal = undefined;
                this.closeAndReconnect();
              }, WEB_SOCKET_CONSTANTS.LOADING_SCREENS_TIME_MS);
            }
            break;

          case "close":
            if (this.timeoutModal) {
              clearTimeout(this.timeoutModal);
              this.timeoutModal = undefined;
            }
            break;
        }
      }
    });
  }

  private loadingScreenTimer() {
    this.loaderService.loaderSubject.subscribe((event) => {
      if (event.show) {
        if (!this.timeoutLoader) {
          this.timeoutLoader = setTimeout(() => {
            this.loaderService.hide();
            this.timeoutLoader = undefined;
            this.closeAndReconnect();
          }, WEB_SOCKET_CONSTANTS.LOADING_SCREENS_TIME_MS);
        }
      } else {
        if (this.timeoutLoader) {
          clearTimeout(this.timeoutLoader);
          this.timeoutLoader = undefined;
        }
      }
    });
  }

  private loadScreenTimer() {
    this.modalScreenTimer();
    this.loadingScreenTimer();
  }
}
