import {
  DEFAULT_ASSETS,
  DEFAULT_ERROR_OPERATORS,
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
} from "../customer-status-code-websocket";

/* Operadores De Seguridad */
export const OPR: {
  [key: string]: IStatusCodesWsError | IStatusCodesWsSuccess;
} = {
  OPR101: {
    ...DEFAULT_ERROR_OPERATORS,
    code: "OPR101",
    eventName: "Error data no encontrada en operadores",
    messageScreen: "Gestiona la cuenta del cliente por el proceso tradicional",
    motivo: "No encuentra información en AEL, Mareigua y SOI",
  },
  OPR102: {
    code: "OPR102",
    success: false,
    titleScreen: DEFAULT_ASSETS.ESTAMOS_PRESENTANDO_ALGUNOS_INCONVENIENTES,
    messageScreen: "Intenta Nuevamente",
    icon: DEFAULT_ASSETS.ASSETS·IMG·LAPTOP·FALLA·SVG,
    showCode: true,
    eventName: "Fallo en consulta de operadores de seguridad",
    motivo: "Fallo en consulta de operadores de seguridad",
    eventCode: 3401,
    eventMnemonic: "FE_OPRError",
  },
  OPR103: {
    code: "OPR103",
    success: false,
    titleScreen: DEFAULT_ASSETS.NO_PODEMOS_ATENDER_LA_SOLICITUD,
    messageScreen: DEFAULT_ASSETS.NO_CUMPLE_CON_POLITICAS_REQUERIDAS,
    icon: DEFAULT_ASSETS.ASSETS·IMG·CA_INVALID_CUSTOMER·SVG,
    showCode: true,
    eventName: "Fallo en consulta de operadores de seguridad",
    motivo: "Ingresos mínimos Operadores para cuenta nómina seleccionada",
    eventCode: 3402,
    eventMnemonic: "FE_OPRError",
  },
};
