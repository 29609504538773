import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormMemoryStorageService } from "@avaldigitallabs/adl-core";
import { Observable, of, zip } from "rxjs";
import { map } from "rxjs/operators";
import { AdlFormGroup } from "src/app/core/models/adl-form-group";
import { SessionStorageCryptService } from "src/app/core/services/session-storage-crypt/session-storage-crypt.service";
import { isNullOrUndefined } from "util";
import { ANALYTICS_EVENT_CONFIG } from "../../../../commons/constants/global-constants";
import { FieldsName } from "../../config/fields-name";
import {
  IAnalyticsEventAuditData,
  IAnalyticsEventItemDetail,
  IAnalyticsEventMessage,
  IAnalyticsEventSource,
  IControlsEventAnalytics,
  IEventLog,
} from "../../models/event-message";
import { CookieService } from "../cookies/cookie.service";
import { IpService } from "../ip/ip.service";
@Injectable({
  providedIn: "root",
})
export class AnalyticsEventBuilderService {
  constructor(
    protected fs: FormMemoryStorageService,
    private cookieService: CookieService,
    private ipService: IpService,
    private sessionStorageCrypt: SessionStorageCryptService,
  ) {}
  public createEventMessage(
    eventLog: IEventLog,
    status: boolean,
    controls?: IControlsEventAnalytics,
    form?: FormGroup | AdlFormGroup,
  ): Observable<IAnalyticsEventMessage> {
    let detailsLis: Observable<IAnalyticsEventItemDetail[]>;
    // tslint:disable-next-line: prettier
    if (controls?.formKeys) {
      detailsLis = this.getDetailsList(controls.formKeys, form);
    } else if (controls?.eventDetails) {
      detailsLis = of(controls.eventDetails);
    } else {
      detailsLis = of([]);
    }
    return zip(detailsLis, this.getAuditData(), this.getSourceData()).pipe(
      map(([details, audit, source]) => ({
        version: ANALYTICS_EVENT_CONFIG.version,
        eventCode: eventLog.eventCode,
        eventMnemonic: eventLog.eventMnemonic,
        eventName: eventLog.eventName,
        timestamp: new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000,
        ).toISOString(),
        result: status,
        details,
        audit,
        source,
      })),
    );
  }
  private getDetailsList(
    controls?: string[],
    form?: FormGroup | AdlFormGroup,
  ): Observable<IAnalyticsEventItemDetail[]> {
    let itemsDetailList = [];
    itemsDetailList = Object.keys(form.controls)
      .filter((key) => controls.includes(key))
      .map((key) => ({ key, value: form.get(key).value }))
      .map((entry) => ({
        key: FieldsName[entry.key] ? FieldsName[entry.key] : entry.key,
        value:
          typeof entry.value === "object"
            ? JSON.stringify(entry.value)
            : String(entry.value),
      }));
    return of(itemsDetailList);
  }
  private getAuditData(): Observable<IAnalyticsEventAuditData> {
    let customAdmin: any;
    if (this.sessionStorageCrypt.getItem("id_token_claims_obj")) {
      customAdmin = JSON.parse(
        this.sessionStorageCrypt.getItem("id_token_claims_obj"),
      )["custom:admin"];
    }

    return zip(
      this.getClientId(),
      this.cookieService.getCookie(ANALYTICS_EVENT_CONFIG.sessionId),
      this.ipService.getPublicIp(),
    ).pipe(
      map(([clientId, sessionId, clientIp]) => ({
        application: ANALYTICS_EVENT_CONFIG.application,
        sessionId,
        clientIdType: clientId ? ANALYTICS_EVENT_CONFIG.clientIdType : "",
        clientId,
        channel: customAdmin
          ? "FVE-libranza-Nexa"
          : ANALYTICS_EVENT_CONFIG.channel,
        transactionId: this.fs.form.get("basicData.processId").value,
        requestId: this.fs.form.get("basicData.processId").value,
        ipAddress: clientIp,
        advisorId: JSON.parse(
          this.sessionStorageCrypt.getItem("id_token_claims_obj"),
        )?.email,
      })),
    );
  }
  private getSourceData(): Observable<IAnalyticsEventSource> {
    return of({
      userAgent: navigator.userAgent.toString(),
      rsa: null,
    });
  }
  private getClientId(): Observable<string> {
    let clientId = "";
    if (!!this.fs.form.get("basicData.identification")) {
      clientId = this.fs.form.get("basicData.identification").value;
    }
    return of(clientId);
  }
}
