import { Component, OnInit } from "@angular/core";

@Component({
  selector: "cdao-modal-websocket-disconnect",
  templateUrl: "./modal-websocket-disconnect.component.html",
  styleUrls: ["./modal-websocket-disconnect.component.scss"],
})
export class ModalWebsocketDisconnectComponent implements OnInit {
  lottieConfig = {
    path: "assets/animations/bocc-spinner.json",
    renderer: "svg",
    autoplay: true,
    loop: true,
  };
  ngOnInit() {
    // do nothing.
  }
}
