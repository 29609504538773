import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import * as uuid from "uuid";

@Injectable({
  providedIn: "root",
})
export class UuidService {
  public generateUUID(): Observable<string> {
    return of(uuid.v4().toString());
  }
}
