import { Component, Input, OnInit } from "@angular/core";
@Component({
  selector: "cdao-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnInit {
  ngOnInit() {
    // do nothing.
  }
}
