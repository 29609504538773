import { Injectable } from "@angular/core";
import { FormMemoryStorageService } from "@avaldigitallabs/adl-core";
import { AdlFormControl } from "src/app/core/models/adl-form-control";
import {
  IStatusCodesWsError,
  IStatusCodesWsSuccess,
  STATUS_CODES_WS,
  StatusCodesWSType,
} from "src/app/core/models/status-codes";
import { SessionStorageCryptService } from "src/app/core/services/session-storage-crypt/session-storage-crypt.service";
import { EVENT_DIRECTORY } from "../../config/event.directory";
import { FieldsName } from "../../config/fields-name";
import { IControlsEventAnalytics, IEventLog } from "../../models/event-message";
import { AnalyticsLoggerService } from "./analytics-logger.service";
@Injectable({
  providedIn: "root",
})
export class AnalyticsDispatcherService {
  constructor(
    protected fs: FormMemoryStorageService,
    private analyticsLogger: AnalyticsLoggerService,
    private sessionStorageCrypt: SessionStorageCryptService,
  ) {}

  public logIdentityEvent(errorEventCode?: StatusCodesWSType) {
    const ACEPT_TERMS_VERSION = "1.1";
    const outsideFieldsToAddInTheLog = {
      accountClass: this.fs.form.get("accountType.accountClass").value,
      stateResources: this.fs.form.get("filterQuestions.stateResources").value,
      usPerson: this.fs.form.get("filterQuestions.usPerson").value,
      foreignCurrency: this.fs.form.get("filterQuestions.foreignCurrency")
        .value,
    };

    Object.keys(outsideFieldsToAddInTheLog).forEach((field) => {
      this.fs.form.addControl(
        field,
        new AdlFormControl(outsideFieldsToAddInTheLog[field]),
      );
    });

    this.fs.form.addControl(
      "acceptTermsVersion",
      new AdlFormControl(ACEPT_TERMS_VERSION),
    );
    this.fs.form.addControl(
      "accountClass",
      new AdlFormControl(this.fs.form.get("accountType.accountClass").value),
    );

    const controlsToLog: IControlsEventAnalytics = {
      formKeys: [
        "firstName",
        "middleName",
        "surname",
        "middleSurname",
        "documentType",
        "phone",
        "email",
        "acceptTerms",
        "identification",
        "digitalSignature",
        "acceptTerms",
        "acceptTermsVersion",
        "accountClass",
        "stateResources",
        "usPerson",
        "foreignCurrency",
        errorEventCode ? "eventCode" : null,
        errorEventCode ? "eventDescription" : null,
      ],
    };
    this.analyticsLogger.logAnalyticsEvent(
      errorEventCode
        ? this.analyticsLogger.eventDirectory.errorIdentity
        : this.analyticsLogger.eventDirectory.identity,
      !errorEventCode,
      controlsToLog,
      this.fs.form,
      errorEventCode,
    );
  }

  public logValidateAddresEvent(errorEventCode?: StatusCodesWSType) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: [
        "addressOptions",
        errorEventCode ? "eventCode" : null,
        errorEventCode ? "eventDescription" : null,
      ],
    };
    this.analyticsLogger.logAnalyticsEvent(
      errorEventCode
        ? this.analyticsLogger.eventDirectory.errorValidateAddress
        : this.analyticsLogger.eventDirectory.validateAddress,
      !errorEventCode,
      controlsToLog,
      this.fs.form,
      errorEventCode,
    );
  }

  public logOtpEvent(errorEventCode?: StatusCodesWSType) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: [
        errorEventCode ? "eventCode" : null,
        errorEventCode ? "eventDescription" : null,
      ],
    };
    this.analyticsLogger.logAnalyticsEvent(
      errorEventCode
        ? this.analyticsLogger.eventDirectory.errorOtpManagment
        : this.analyticsLogger.eventDirectory.otpManagment,
      !errorEventCode,
      controlsToLog,
      this.fs.form,
      errorEventCode,
    );
  }
  public logLaboralDataEvent1(successfulEvent: boolean) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: [
        "entryDate",
        "occupation",
        "job",
        "company",
        "companyRole",
        "economicActivity",
      ],
    };

    this.analyticsLogger.logAnalyticsEvent(
      successfulEvent
        ? this.analyticsLogger.eventDirectory.laboralData1
        : this.analyticsLogger.eventDirectory.errorLaboralData1,
      successfulEvent,
      controlsToLog,
      this.fs.form,
    );
  }

  public logLaboralDataEvent2(errorEventCode?: StatusCodesWSType) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: [
        "laboralCity",
        "laboralAdress",
        "complementAdress",
        "laboralPhone",
        errorEventCode ? "eventCode" : null,
        errorEventCode ? "eventDescription" : null,
      ],
    };
    this.analyticsLogger.logAnalyticsEvent(
      errorEventCode
        ? this.analyticsLogger.eventDirectory.errorLaboralData2
        : this.analyticsLogger.eventDirectory.laboralData2,
      !errorEventCode,
      controlsToLog,
      this.fs.form,
      errorEventCode,
    );
  }
  public logDeliveryCardLog(errorEventCode?: StatusCodesWSType) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: [
        "cardDelivery",
        errorEventCode ? "eventCode" : null,
        errorEventCode ? "eventDescription" : null,
      ],
    };
    this.analyticsLogger.logAnalyticsEvent(
      errorEventCode
        ? this.analyticsLogger.eventDirectory.errorDeliveryCard
        : this.analyticsLogger.eventDirectory.deliveryCard,
      !errorEventCode,
      controlsToLog,
      this.fs.form,
      errorEventCode,
    );
  }
  public logPersonalInfoEvent(successfulEvent: boolean) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: [
        "birthCountry",
        "birthCity",
        "birthDay",
        "expeditionCity",
        "expeditionDate",
      ],
    };
    this.analyticsLogger.logAnalyticsEvent(
      this.analyticsLogger.eventDirectory.personalInformation1,
      successfulEvent,
      controlsToLog,
      this.fs.form,
    );
  }

  public logPersonalInfoEvent2(errorEventCode?: StatusCodesWSType) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: [
        "gender",
        "civilStatus",
        "educationalLevel",
        "residenceCity",
        "residentialAddress",
        "residentialComplement",
        errorEventCode ? "eventCode" : null,
        errorEventCode ? "eventDescription" : null,
      ],
    };
    this.analyticsLogger.logAnalyticsEvent(
      this.analyticsLogger.eventDirectory.personalInformation2,
      !errorEventCode,
      controlsToLog,
      this.fs.form,
      errorEventCode,
    );
  }

  public logOfficeAsosiationEvent(errorEventCode?: StatusCodesWSType) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: ["bankOffice", "residenceCity"],
    };
    this.analyticsLogger.logAnalyticsEvent(
      this.analyticsLogger.eventDirectory.officeAssociation,
      !errorEventCode,
      controlsToLog,
      this.fs.form,
      errorEventCode,
    );
  }

  public logMngAccountEvent(errorEventCode?: StatusCodesWSType) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: [
        "withCard",
        errorEventCode ? "eventCode" : null,
        errorEventCode ? "eventDescription" : null,
      ],
    };
    this.analyticsLogger.logAnalyticsEvent(
      errorEventCode
        ? this.analyticsLogger.eventDirectory.errorManageAccount
        : this.analyticsLogger.eventDirectory.manageAccount,
      !errorEventCode,
      controlsToLog,
      this.fs.form,
      errorEventCode,
    );
  }

  public logMonthlyTransactionEvent(errorEventCode?: StatusCodesWSType) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: [
        "creditAmount",
        "debitAmount",
        errorEventCode ? "eventCode" : null,
        errorEventCode ? "eventDescription" : null,
      ],
    };
    this.analyticsLogger.logAnalyticsEvent(
      this.analyticsLogger.eventDirectory.monthlyTransactions,
      !errorEventCode,
      controlsToLog,
      this.fs.form,
      errorEventCode,
    );
  }

  public logFinancialDataEvent(errorEventCode?: StatusCodesWSType) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: [
        "typeOfContract",
        "monthlyIncome",
        "monthlyExpenses",
        "assetValue",
        "liabilitiesValue",
        "otherIncome",
        "declareResources",
        errorEventCode ? "eventCode" : null,
        errorEventCode ? "eventDescription" : null,
      ],
    };
    this.analyticsLogger.logAnalyticsEvent(
      errorEventCode
        ? this.analyticsLogger.eventDirectory.errorFinancialData
        : this.analyticsLogger.eventDirectory.financialData,
      !errorEventCode,
      controlsToLog,
      this.fs.form,
      errorEventCode,
    );
  }

  public logWebSocketEvent(eventCode: StatusCodesWSType, success: boolean) {
    const controlsToLog: IControlsEventAnalytics = {
      eventDetails: [
        { key: FieldsName.eventCode, value: eventCode as string },
        {
          key: FieldsName.eventDescription,
          value: STATUS_CODES_WS[eventCode]?.eventName,
        },
        {
          key: FieldsName.motivo,
          value: STATUS_CODES_WS[eventCode]?.motivo,
        },
        {
          key: FieldsName.keyEmail,
          value:
            " " +
            JSON.parse(this.sessionStorageCrypt.getItem("id_token_claims_obj"))
              ?.email +
            " ",
        },
        {
          key: FieldsName.keyNameEmail,
          value:
            " " +
            JSON.parse(this.sessionStorageCrypt.getItem("id_token_claims_obj"))
              ?.name +
            " ",
        },
        {
          key: FieldsName.keyName,
          value:
            " " +
            JSON.parse(this.sessionStorageCrypt.getItem("id_token_claims_obj"))
              ?.given_name +
            " " +
            JSON.parse(this.sessionStorageCrypt.getItem("id_token_claims_obj"))
              ?.family_name +
            " ",
        },
      ],
    };
    const statusCodeWS = STATUS_CODES_WS[eventCode] as IStatusCodesWsSuccess;
    const statusCodeErrors = STATUS_CODES_WS[eventCode] as IStatusCodesWsError;
    let event: IEventLog;
    if (statusCodeWS) {
      if (STATUS_CODES_WS[eventCode].success) {
        event = {
          eventCode: statusCodeWS.eventCode,
          eventMnemonic: statusCodeWS.eventMnemonic,
          eventName: statusCodeWS.eventName,
        };
      } else {
        // event = EVENT_DIRECTORY.stepMachineError;
        event = {
          eventCode: statusCodeErrors.eventCode,
          eventMnemonic: statusCodeErrors.eventMnemonic,
          eventName: statusCodeErrors.eventName,
        };
      }
    } else {
      event = EVENT_DIRECTORY.stepMachineErrorNoMapper;
    }
    this.analyticsLogger.logAnalyticsEvent(event, success, controlsToLog);
  }

  public logViewAdvisors(errorFlag) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: ["addressOptions", "eventCode", "eventDescription"],
    };
    this.analyticsLogger.logAnalyticsEvent(
      errorFlag == 1
        ? this.analyticsLogger.eventDirectory.logFsvAdvisorsError
        : this.analyticsLogger.eventDirectory.logFsvAdvisorsOk,
      errorFlag == 1 ? false : true,
      controlsToLog,
      this.fs.form,
      errorFlag,
    );
  }
  public logAddAdvisors(errorFlag) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: ["addressOptions", "eventCode", "eventDescription"],
    };
    this.analyticsLogger.logAnalyticsEvent(
      errorFlag == 1
        ? this.analyticsLogger.eventDirectory.logFsvAddAdvisorError
        : this.analyticsLogger.eventDirectory.logFsvAddAdvisorOk,
      errorFlag == 1 ? false : true,
      controlsToLog,
      this.fs.form,
      errorFlag,
    );
  }
  public logErAdvisors(errorFlag) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: ["addressOptions", "eventCode", "eventDescription"],
    };
    this.analyticsLogger.logAnalyticsEvent(
      errorFlag == 1
        ? this.analyticsLogger.eventDirectory.logFsvErAdvisorError
        : this.analyticsLogger.eventDirectory.logFsvErAdvisorOk,
      errorFlag == 1 ? false : true,
      controlsToLog,
      this.fs.form,
      errorFlag,
    );
  }
  public logEdAdvisors(errorFlag) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: ["addressOptions", "eventCode", "eventDescription"],
    };
    this.analyticsLogger.logAnalyticsEvent(
      errorFlag == 1
        ? this.analyticsLogger.eventDirectory.logFsvEdAdvisorError
        : this.analyticsLogger.eventDirectory.logFsvEdAdvisorOk,
      errorFlag == 1 ? false : true,
      controlsToLog,
      this.fs.form,
      errorFlag,
    );
  }
  public logDisenableAdvisors(errorFlag) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: ["addressOptions", "eventCode", "eventDescription"],
    };
    this.analyticsLogger.logAnalyticsEvent(
      errorFlag == 1
        ? this.analyticsLogger.eventDirectory.logFsvDisenableAdvisorError
        : this.analyticsLogger.eventDirectory.logFsvDisenableAdvisor,
      errorFlag == 1 ? false : true,
      controlsToLog,
      this.fs.form,
      errorFlag,
    );
  }
  public logEnableAdvisors(errorFlag) {
    const controlsToLog: IControlsEventAnalytics = {
      formKeys: ["addressOptions", "eventCode", "eventDescription"],
    };
    this.analyticsLogger.logAnalyticsEvent(
      errorFlag == 1
        ? this.analyticsLogger.eventDirectory.logFsvEnableAdvisorError
        : this.analyticsLogger.eventDirectory.logFsvEnableAdvisor,
      errorFlag == 1 ? false : true,
      controlsToLog,
      this.fs.form,
      errorFlag,
    );
  }
}
